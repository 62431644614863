import React from "react";

interface CTABtnProps {
  text: string | JSX.Element;
  type: "button" | "submit" | "reset";
  color?: string;
  reversed?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export const CTAbtn = (props: CTABtnProps) => {
  const { type, text, color, reversed, onClick, ...rest } = props;

  return (
    <>
      {!reversed && (
        <button
          type={type}
          onClick={onClick}
          className={`text-white transition duration-300 hover:text-${color} bg-${color} hover:bg-white border-2 border-transparent hover:border-${color} font-medium rounded-full text-sm w-full py-2 px-5 text-center`}
          {...rest}
        >
          {text}
        </button>
      )}
      {reversed && (
        <button
          type={type}
          onClick={onClick}
          className={`text-${color} transition duration-300 text-${color} hover:text-white bg-white hover:bg-${color} border-2 border-${color} hover:border-${color} font-medium rounded-full text-sm w-full py-2 px-5 text-center`}
          {...rest}
        >
          {text}
        </button>
      )}
    </>
  );
};

interface CheckboxToggleBtnProps {
  checkedText: string;
  uncheckedText: string;
  isDisabled: boolean;
  isChecked: boolean;
}

export const CheckboxToggleBtn = (props: CheckboxToggleBtnProps) => {
  const { checkedText, uncheckedText, isDisabled, isChecked } = props;
  return (
    <div className="toggleSlider flex items-center">
      <div className="off-state-label mr-2 text-xs">{uncheckedText}</div>

      <label className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            type="checkbox"
            id="toggleB"
            className="sr-only"
            disabled={isDisabled}
            defaultChecked={isChecked}
          />
          <div className="slider block bg-gray-10 border border-black w-6 h-3 rounded-full"></div>
          <div className="dot absolute left-1 top-0.5 bg-black w-2 h-2 rounded-full transition"></div>
        </div>
      </label>
      <div className="on-state-label ml-2 text-xs">{checkedText}</div>
    </div>
  );
};
