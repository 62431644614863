import React from "react";
import logoReversed from "../../assets/logos/onesource-reversed.svg";
import { PageMeta } from "../shared/PageMeta";
import { LoginForm } from "./LoginForm";

export default function Login() {
  return (
    <>
      <PageMeta title="Login" />
      <main className="fixed top-0 left-0 right-0 w-screen h-screen landing-page-bg">
        <div className="relative w-full h-full overflow-y-scroll">
          <div className="flex flex-col w-2/3 lg:w-1/3 md:w-1/2 mx-auto">
            <img
              className="mt-14"
              src={logoReversed}
              alt="OneSource Solutions"
            />
            <div className="block w-full mb-10 xl:px-20 lg:px-15 md:px-10">
              <span className="block text-left text-2xl text-white my-8">
                Partner Portal Login
              </span>
              <LoginForm />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
