import React, { ReactElement } from "react";
import ProfileBlock from "../../components/dashboard/ProfileBlock";
import { slashDate } from "../../helpers/dateTime";
import { Agent } from "../../interfaces/Agent";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

interface AgentBlockProps {
  device: string;
  agents: Agent[];
  setDetailsOpen: (value: boolean) => void;
  setAgentDetails: (value) => void;
}

export default function AgentBlock(props: AgentBlockProps): ReactElement | any {
  const { device, agents, setDetailsOpen, setAgentDetails } = props;

  const openDetailsDrawer = (agent) => {
    setAgentDetails(agent);
    setDetailsOpen(true);
  };

  if (agents && device === "desktop") {
    return agents.map((agent: any, index) => (
      <div
        key={index}
        className="grid grid-cols-[3fr_1fr_1fr_1fr_0.5fr] gap-5 items-center border-b p-5 shodow hover:shadow-md"
      >
        <div className="cursor-pointer">
          <ProfileBlock
            name={`${agent.LastName}, ${agent.FirstName}`}
            title={`Office: ${agent.Company}`}
            url={agent.Photo}
            onClick={() => {
              openDetailsDrawer(agent);
            }}
          />
        </div>

        <span className="text-sm font-medium">
          {slashDate(agent.DateAdded)}
        </span>
        <span className="text-sm font-medium">{agent.LeadsSubmitted}</span>
        <span className="text-sm font-medium">{agent.LeadsSold}</span>

        <div className="flex justify-end">
          <Link to={`/`} state={{ agentToken: agent.AgentToken }}>
            <Tooltip title="View As">
              <i className="fas fa-eye"></i>
            </Tooltip>
          </Link>
        </div>
      </div>
    ));
  }
  if (agents && device === "mobile") {
    return agents.map((agent: any, index) => (
      <div
        key={index}
        className="flex flex-col border rounded-xl w-full p-5 mb-5"
      >
        <div className="grid grid-cols-[2.5fr_1fr] gap-2 gap-y-7 w-full pb-5">
          <div className="flex flex-col ">
            <span className="text-xs mb-1">Agent/Office Name</span>
            <span className="text-sm font-light">
              {agent.LastName}, {agent.FirstName}
            </span>
            <span className="text-xs font-light">Office : {agent.Company}</span>
          </div>
          <div className="flex flex-col text-right">
            <span className="text-xs mb-1">Date Started</span>

            <span className="text-sm font-light">
              {slashDate(agent.DateAdded)}
            </span>
          </div>
          <div className="flex flex-col ">
            <span className="text-xs mb-1">Leads Submitted</span>
            <span className="text-sm font-light">{agent.LeadsSubmitted}</span>
          </div>

          <div className="flex flex-col text-right">
            <span className="text-xs mb-1">Leads Sold</span>
            <span className="text-sm font-light">{agent.LeadsSold}</span>
          </div>
        </div>
        <div className="flex justify-between">
          <Link
            to={`/`}
            state={{ agentToken: agent.AgentToken }}
            className="w-fit text-sm font-medium"
          >
            VIEW AS AGENT
          </Link>
          <button onClick={() => openDetailsDrawer(agent)}>VIEW DETAILS</button>
        </div>
      </div>
    ));
  }
}
