import { Request } from "../Request";

export async function toggleShareRevenueRequest(
  PortalToken: string | null | undefined,
  UserLevel: string | null | undefined,
  OfficeToken: string | null,
  RevenueSharing: string
) {
  const data = {
    PortalToken,
    UserLevel,
    OfficeToken,
    RevenueSharing,
  };
  const response = await Request("set-revenue-sharing", data);
  return response.data;
}
