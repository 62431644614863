import { format, isValid } from "date-fns";

export function slashDate(dateStr: string | null): string {
  if (dateStr === null || !isValid(new Date(dateStr))) {
    return "00/00/0000";
  }
  return format(new Date(dateStr), "MM/dd/yyyy");
}

export function formatDate(dateStr: string | null): string {
  if (dateStr === null || !isValid(new Date(dateStr))) {
    return "00/00/0000";
  }
  return format(new Date(dateStr), "yyyy-MM-dd");
}

export function getPastDate(days: number) {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() - days);
}
