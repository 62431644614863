import React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { LoggedInUser } from "../../interfaces/LoggedInUser";
import altProfileImg from "../../assets/alts/profile-alt.png";
import altCompanyImg from "../../assets/alts/company-alt.png";
import { Link } from "react-router-dom";
import { CTAbtn } from "../form/Buttons";
import { useAuthContext } from "../../context/AuthContext";

interface AccountMenuProps {
  loggedInUser: LoggedInUser | null;
}

export default function AccountMenu(props: AccountMenuProps) {
  const { loggedInUser } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [, authActions] = useAuthContext();
  const handleLogout = () => {
    authActions.signOut();
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 3 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <img
              src={
                loggedInUser && loggedInUser.UserLevel === "Agent"
                  ? loggedInUser.Photo !== ""
                    ? loggedInUser.Photo
                    : altProfileImg
                  : loggedInUser && loggedInUser?.TerritoryRepPhoto !== ""
                  ? loggedInUser?.TerritoryRepPhoto
                  : altCompanyImg
              }
              alt="Profile"
              className="w-10 h-10 rounded-full cursor-pointer object-cover"
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 20,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="flex w-full p-2 items-center min-w-[300px]">
          <img
            src={
              loggedInUser && loggedInUser.UserLevel === "Agent"
                ? loggedInUser.Photo !== ""
                  ? loggedInUser.Photo
                  : altProfileImg
                : loggedInUser && loggedInUser?.TerritoryRepPhoto !== ""
                ? loggedInUser?.TerritoryRepPhoto
                : altCompanyImg
            }
            alt="Profile"
            className="w-10 h-10 rounded-full object-cover"
          />
          <div className="flex flex-col ml-2">
            <span>
              {loggedInUser && loggedInUser.UserLevel !== "Agent"
                ? loggedInUser?.Company
                : `${loggedInUser?.FirstName} ${loggedInUser?.LastName}`}
            </span>
          </div>
        </div>

        <Divider />
        <Link to={"/myaccount"}>
          <MenuItem>
            <ListItemIcon>
              <i className="fa-regular fa-user text-lg"></i>
            </ListItemIcon>
            My Account
          </MenuItem>
        </Link>
        <Link to={"/mydocuments"}>
          <MenuItem>
            <ListItemIcon>
              <i className="fa-regular fa-file-lines text-lg"></i>
            </ListItemIcon>
            My Documents
          </MenuItem>
        </Link>
        <Divider />
        <div className="px-5 mt-2">
          <CTAbtn
            type="button"
            text="LOG OUT"
            color="os-btn-third"
            onClick={handleLogout}
          />
        </div>
      </Menu>
    </React.Fragment>
  );
}
