type FormatPriceOptions = Partial<{
  compact: boolean;
  includeCurrency: boolean;
}>;

function compactPrice(price: string) {
  return price.replace(".00", "");
}

export function floatToFormattedPrice(
  price: number,
  options: FormatPriceOptions = {}
): string {
  const { compact = true, includeCurrency = true } = options;
  const out = price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","); // regexp adds commas as the thousands separator
  return `${includeCurrency ? "$" : ""}${compact ? compactPrice(out) : out}`;
}
