import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Login from "./Login";

function AuthIndex() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`/login`} element={<Login />} />
        <Route path={`/`} element={<Login />} />
        <Route path={`/forgotpassword`} element={<ForgotPassword />} />
        <Route path={`/resetpassword`} element={<ResetPassword />} />
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default AuthIndex;
