import React from "react";
import { Link } from "react-router-dom";
import logoReversed from "../../assets/logos/onesource-reversed.svg";
import { PageMeta } from "../shared/PageMeta";
import { ForgotPasswordForm } from "./ForgotPasswordForm";

export default function ForgotPassword() {
  return (
    <>
      <PageMeta title="Forgot Password" />
      <main className="fixed top-0 left-0 right-0 w-screen h-screen landing-page-bg">
        <div className="relative w-full h-full overflow-y-scroll">
          <div className="flex flex-col w-2/3 lg:w-1/3 md:w-1/2 mx-auto">
            <Link to={"/"}>
              <img
                className="mt-14"
                src={logoReversed}
                alt="OneSource Solutions"
              />
            </Link>
            <div className="block w-full mb-10 xl:px-20 lg:px-15 md:px-10">
              <span className="block text-left text-2xl text-white my-8">
                Forgot Password?
              </span>
              <ForgotPasswordForm />
              <div className="w-full flex justify-end">
                <Link
                  to="/"
                  className="text-white font-light text-sm text-right hover:underline mt-5"
                >
                  Return to Login Page
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
