import React, { useMemo, useState, useEffect } from "react";
import { useQuery } from "react-query";
import { getServiceActivityRequest } from "../../api/dashboard/getServiceActivityRequest";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { LoggedInUser } from "../../interfaces/LoggedInUser";
import InlineLoader from "../shared/InlineLoader";
import MonthRangePickerModal from "../shared/MonthRangePickerModal";
import { format, startOfYear, parse } from "date-fns";

interface MonthlyClientsChartProps {
  height?: number;
  loggedInUser: LoggedInUser | null;
  OfficeToken?: string | null | undefined;
  AgentToken?: string | null | undefined;
}

const monthLabels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const MonthlyClientsChart = (props: MonthlyClientsChartProps) => {
  const firstDayOfYear = format(startOfYear(new Date()), "yyyy-MM-dd");
  const today = format(new Date(), "yyyy-MM-dd");
  const todayDate = new Date();
  const maxYear = todayDate.getUTCFullYear();

  const { height = 285, loggedInUser, OfficeToken, AgentToken } = props;
  const [beginDate, setBeginDate] = useState(firstDayOfYear);
  const [endDate, setEndDate] = useState(today);
  const query = useQuery(
    [
      "get-monthly-stats-for-chart",
      OfficeToken,
      AgentToken,
      beginDate,
      endDate,
    ],
    () =>
      getServiceActivityRequest(
        loggedInUser?.PortalToken,
        loggedInUser?.UserLevel,
        beginDate,
        endDate,
        AgentToken,
        OfficeToken
      )
  );

  const { data, refetch, isLoading } = query;
  useEffect(() => {
    refetch();
  }, [refetch]);

  const categories = useMemo(() => {
    if (isLoading) {
      return [];
    }
    let arry: string[] = [];
    data?.ClientsByMonth?.forEach((client) => {
      let split = client?.Date.split("/");
      let monthIndex = parseInt(split[0]) - 1;
      let month = monthLabels[monthIndex];
      let label = month + " " + split[1];
      arry.push(label);
    });
    return arry;
  }, [data, isLoading]);

  const renewalsData = useMemo(() => {
    if (isLoading) {
      return [];
    }
    let arry: number[] = [];
    data?.ClientsByMonth.forEach((client) => {
      if (client?.NumClients === null) client.NumClients = "0";
      arry.push(parseFloat(client?.NumClients));
    });
    return arry;
  }, [data, isLoading]);

  const handleMonthRangeApply = (begin, end) => {
    setBeginDate(begin);
    setEndDate(end);
  };
  if (query.isError) {
    return (
      <span className="font-bold text-sm">
        We were not able to load this chart. Reload page and try again
      </span>
    );
  }
  const options: ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "65%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#46C5E1"],

    xaxis: {
      categories,
    },
    fill: {
      opacity: 1,
    },
  };
  return (
    <div className="flex flex-col w-full mb-10">
      <div className="flex items-center mb-6">
        <span className="text-xl font-light tracking-wider mr-5">
          INCOMING CLIENTS BY MONTH
        </span>
        <MonthRangePickerModal
          initialBeginDate={parse(firstDayOfYear, 'yyyy-MM-dd', new Date())}
          initialEndDate={parse(today, 'yyyy-MM-dd', new Date())}
          minYear={2013}
          maxYear={maxYear}
          handleApply={handleMonthRangeApply}
        />
      </div>

      <div className="w-full">
        {!isLoading && (
          <Chart
            options={options}
            height={height}
            type="bar"
            series={[
              {
                name: "Clients",
                data: renewalsData,
              },
            ]}
          />
        )}
        {isLoading && <InlineLoader />}
      </div>
    </div>
  );
};

export default MonthlyClientsChart;
