import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { CTAbtn } from "../form/Buttons";
import { useForm } from "react-hook-form";
import Tooltip from "@mui/material/Tooltip";
import InlineLoader from "../shared/InlineLoader";
interface AgentsSearchFormValues {
  searchType: string;
  searchValue: string;
}

const defaultValues: Partial<AgentsSearchFormValues> = {
  searchValue: "",
  searchType: "Name",
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <i className="fa-regular fa-times text-lg"></i>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
interface AgentsSearchProps {
  searchValue: string;
  setSearchValue: (val: string) => void;
  searchType: string;
  setSearchType: (val: string) => void;
  refetch: () => void;
}
export default function AgentsSearch(props: AgentsSearchProps) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const { setSearchValue, setSearchType, refetch } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = async () => {
    reset(defaultValues);
    setSearchType(getValues("searchType"));
    setSearchValue(getValues("searchValue"));
    setResetLoading(true);
    await refetch();
    setResetLoading(false);
    setOpen(false);
  };

  const handleApply = async () => {
    try {
      setSearchType(getValues("searchType"));
      setSearchValue(getValues("searchValue"));
      setLoading(true);
      await refetch();
      setLoading(false);
      setOpen(false);
    } catch (error) {}
  };
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    getValues,
  } = useForm<AgentsSearchFormValues>({
    defaultValues,
  });
  return (
    <div>
      <button
        onClick={handleClickOpen}
        className="hidden md:flex flex-col justify-around items-center ml-5"
      >
        <Tooltip title="Search">
          <i className="fas fa-search text-lg"></i>
        </Tooltip>
      </button>

      {/* </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Agents
        </BootstrapDialogTitle>
        <form onSubmit={handleSubmit(handleApply)} noValidate>
          <DialogContent dividers>
            <div className="flex flex-col min-w-[300px]">
              <label className="block">
                <div className="flex">
                  <span className="text-black block text-sm font-light mb-2">
                    Search By
                  </span>
                </div>

                <select
                  className="bg-white w-full border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                  {...register("searchType")}
                >
                  <option value="Name">Name</option>
                  <option value="Email">Email</option>
                </select>
                <span className=" text-os-primary block text-sm mt-2 -mb-4">
                  {errors.searchType?.message ?? ""}
                </span>
              </label>
              <label className="block mt-5">
                <div className="flex">
                  <span className="block text-black text-sm font-light mb-2">
                    Search
                  </span>
                </div>
                <div className="relative">
                  <input
                    type="search"
                    placeholder="Search"
                    className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
                                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
                                "
                    {...register("searchValue", {
                      minLength: {
                        value: 4,
                        message: "Please enter at least 4 characters",
                      },
                    })}
                  />
                  <i className="fa-light fa-magnifying-glass absolute top-3 right-3"></i>
                </div>
                <span className="text-os-primary block text-sm mt-2 -mb-4">
                  {errors?.searchValue?.message ?? ""}
                </span>
              </label>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex flex-col w-full p-2">
              <div className="mb-2">
                <CTAbtn
                  type="reset"
                  disabled={resetLoading}
                  text={
                    resetLoading ? (
                      <div className="flex justify-center items-center gap-2">
                        Resetting... <InlineLoader />
                      </div>
                    ) : (
                      <span>Reset Filters</span>
                    )
                  }
                  color="os-btn-third"
                  reversed={true}
                  onClick={handleClose}
                />
              </div>
              <CTAbtn
                type="submit"
                disabled={loading}
                text={
                  loading ? (
                    <div className="flex justify-center items-center gap-2">
                      Applying... <InlineLoader />
                    </div>
                  ) : (
                    <span>Apply And Close</span>
                  )
                }
                color="os-btn-third"
              />
            </div>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}
