import React, { useState } from "react";
import Nav from "../components/dashboard/Nav";
import Header from "../components/dashboard/Header";
import { useAuthContext } from "../context/AuthContext";
import { AgentAccountForm } from "../components/myaccount/AgentAccountForm";
import { CorporateAccountForm } from "../components/myaccount/CorporateAccountForm";
import altProfileImg from "../assets/alts/profile-alt.png";
import altCompanyImg from "../assets/alts/company-alt.png";
import { PageMeta } from "../components/shared/PageMeta";
// import FileInputComponent from "react-file-input-previews-base64";
// import ImgCrop from 'antd-img-crop';
import { Upload } from "antd";
import { profilePhotoUploadRequest } from "../api/myaccount/profilePhotoUploadRequest";
import { toast } from "react-toastify";
import { getUserDetailsRequest } from "../api/dashboard/getUserDetailsRequest";
import type { UploadFile } from "antd/es/upload/interface";
import { Tooltip } from "@mui/material";

export default function MyAccount() {
  const [authState, authMethods] = useAuthContext();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { loggedInUser } = authState;

  const getBase64 = (file): Promise<string> => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === "string") {
          baseURL = reader.result;
        }
        resolve(baseURL);
      };
    });
  };

  const uploadImage = async (file, remove = false) => {
    if (!remove) {
      setFileList([file.file]);
    }
    const photoString = remove ? "remove" : await getBase64(file.file);
    const data = {
      PortalToken: loggedInUser?.PortalToken,
      UserLevel: loggedInUser?.UserLevel,
      Photo: photoString,
    };
    const resp = await profilePhotoUploadRequest(data);
    if (resp.data.ResultCode === 200) {
      setFileList([]);

      const detailsResp = await getUserDetailsRequest(
        loggedInUser?.PortalToken,
        loggedInUser?.UserLevel
      );
      if (detailsResp.ResultCode === 200) {
        detailsResp.UserLevel = data.UserLevel;
        detailsResp.PortalToken = data.PortalToken;
        authMethods.updateLoggedInUser(detailsResp);
      }
      toast.success("Image uploaded!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
    return file.onSuccess(resp, resp.request);
  };

  return (
    <>
      <PageMeta title="My Account" />
      <Nav loggedInUser={loggedInUser} />
      {/* body of the page */}
      <main className="w-full">
        {/* -------- */}
        <Header title="My Account" loggedInUser={loggedInUser} />
        {/* -------- */}
        <section className="">
          {/*<FileInputComponent*/}
          {/*  labelText="Select"*/}
          {/*  labelStyle={{ fontSize: 14 }}*/}
          {/*  imageContainerStyle={{ borderRadius: 100, width: 200, higth: 200 }}*/}
          {/*  imageStyle={{ borderRadius: 100, width: 100, higth: 100 }}*/}
          {/*  multiple={false}*/}
          {/*  callbackFunction={(file_arr) => {*/}
          {/*  }}*/}
          {/*  accept="image/*"*/}
          {/*/>*/}
          <div className="relative w-full h-full overflow-y-auto">
            <div className="flex flex-col items-center w-2/3 lg:w-1/3 md:w-1/2 mx-auto">
              <img
                className="mt-14 w-40 h-40 rounded-full object-cover mb-20"
                src={
                  loggedInUser?.UserLevel === "Agent"
                    ? loggedInUser?.Photo !== ""
                      ? loggedInUser?.Photo
                      : altProfileImg
                    : loggedInUser?.TerritoryRepPhoto !== ""
                    ? loggedInUser?.TerritoryRepPhoto
                    : altCompanyImg
                }
                alt="OneSource Solutions"
              />
              {loggedInUser?.UserLevel === "Agent" && (
                <>
                  {/*<ImgCrop grid={true} rotate={true} modalTitle={'Crop Image'}>*/}
                  <Upload
                    accept="image/png, image/jpeg"
                    customRequest={uploadImage}
                    multiple={false}
                    fileList={fileList}
                    onRemove={(file) => uploadImage(file, true)}
                  >
                    <Tooltip title="Edit your profile photo">
                      <i className="fas fa-camera" />
                    </Tooltip>
                  </Upload>

                  {/*</ImgCrop>*/}
                </>
              )}

              <div className="block w-full mb-10 xl:px-20 lg:px-15 md:px-10">
                {loggedInUser?.UserLevel === "Agent" && <AgentAccountForm />}
                {loggedInUser?.UserLevel !== "Agent" && (
                  <CorporateAccountForm />
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
