import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import ClientsLeads from "./pages/ClientsLeads";
import Agents from "./pages/Agents";
import { useAuthContext } from "./context/AuthContext";
import AuthIndex from "./components/auth/AuthIndex";
import Offices from "./pages/Offices";
import MyAccount from "./pages/MyAccount";
import ChangePassword from "./pages/ChangePassword";
import ForcePasswordReset from "./pages/ForcePasswordReset";
import MyDocuments from "./pages/MyDocuments";
import HasPerm from "./components/auth/HasPerm";

function App() {
  const [authState] = useAuthContext();
  const { loggedInUser } = authState;

  if (!loggedInUser) {
    return <AuthIndex />;
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={`/`}
          element={
            <ForcePasswordReset>
              <Dashboard />
            </ForcePasswordReset>
          }
        />
        <Route
          path={`/clients`}
          element={
            <HasPerm
              perms={["Agent", "Office", "Corporate"]}
              redirectTo="/"
              userLevel={loggedInUser.UserLevel}
            >
              <ForcePasswordReset>
                <ClientsLeads />
              </ForcePasswordReset>
            </HasPerm>
          }
        />
        <Route
          path={`/agents`}
          element={
            <HasPerm
              perms={["Office", "Corporate"]}
              redirectTo="/"
              userLevel={loggedInUser.UserLevel}
            >
              <ForcePasswordReset>
                <Agents />
              </ForcePasswordReset>
            </HasPerm>
          }
        />
        <Route
          path={`/offices`}
          element={
            <HasPerm
              perms={["Corporate"]}
              redirectTo="/"
              userLevel={loggedInUser.UserLevel}
            >
              <ForcePasswordReset>
                <Offices />
              </ForcePasswordReset>
            </HasPerm>
          }
        />
        <Route
          path={`/myaccount`}
          element={
            <ForcePasswordReset>
              <MyAccount />
            </ForcePasswordReset>
          }
        />
        <Route path={`/changepassword`} element={<ChangePassword />} />
        <Route
          path={`/mydocuments`}
          element={
            <ForcePasswordReset>
              <MyDocuments />
            </ForcePasswordReset>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
