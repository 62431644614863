import axios from "axios";
import md5 from "md5";

function pad(num: number) {
  return ("0" + num).slice(-2);
}

function formatDate(d: Date) {
  return [d.getUTCFullYear(),
      pad(d.getUTCMonth() + 1),
      pad(d.getUTCDate())].join("-") + " " +
    [pad(d.getUTCHours()),
      pad(d.getUTCMinutes())].join(":");
}

export async function Request(endpoint: string, data: object) {
  const url = process.env.REACT_APP_API_URL || "";
  const apiKey = process.env.REACT_APP_API_KEY;
  const secret = process.env.REACT_APP_API_SECRET;
  const postData = {
    APIKey: apiKey,
    Method: endpoint,
    ...data,
  };

  const date = formatDate(new Date());
  const jsonString = JSON.stringify(postData);
  const signature = md5(`${date}${jsonString}${secret}`);
  const headers = {
    'Content-Type': 'application/json',
    accept: 'application/json',
    Signature: signature,
  };
  const result = await axios.post(url, postData, { headers, withCredentials: false });
  if (typeof result !== "object") throw new Error("API - Bad Response");
  // if (result.data.ResultCode < 200 || result.data.ResultCode > 299)
  //   throw new Error(
  //     `API - Error: Result Code ${
  //       result.data.ResultCode ?? result.data.ResultMessage
  //     }`
  //   );

  return result;
}
