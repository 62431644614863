import { Request } from "../Request";

export async function getTopAgentsRequest(
  PortalToken: string | null | undefined,
  UserLevel: string | null | undefined,
  OfficeToken?: string | null
) {
  const data = {
    PortalToken,
    UserLevel,
  };

  if (OfficeToken !== "" && OfficeToken !== null) {
    data["OfficeToken"] = OfficeToken;
  }
  const response = await Request("get-top-agents", data);
  return response.data;
}
