import { Request } from "../Request";

export async function getDocumentsListRequest(
  PortalToken: string | null | undefined,
  UserLevel: string | null | undefined
) {
  const data = {
    PortalToken,
    UserLevel,
  };

  const response = await Request("get-document-list", data);
  return response.data;
}
