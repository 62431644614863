import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { forcePasswordResetRequest } from "../../api/myaccount/forcePasswordResetRequest";
import { useAuthContext } from "../../context/AuthContext";
import { CTAbtn } from "../form/Buttons";

interface ResetPasswordFormValues {
  PortalToken: string;
  UserLevel: string;
  Password: string;
  ConfirmPassword: string;
}

export const ForcePasswordResetForm = () => {
  const [authState, authMethods] = useAuthContext();
  const { loggedInUser } = authState;
  const navigate = useNavigate();

  const PortalToken = loggedInUser?.PortalToken;
  const UserLevel = loggedInUser?.UserLevel;

  if (!PortalToken || !UserLevel) {
    throw new Error(
      "This component should be accessible by logged in user only."
    );
  }

  const defaultValues: Partial<ResetPasswordFormValues> = {
    PortalToken,
    UserLevel,
    Password: "",
    ConfirmPassword: "",
  };

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    register,
    watch,
  } = useForm<ResetPasswordFormValues>({ defaultValues });

  const onSubmit = async (formData: ResetPasswordFormValues) => {
    try {
      const { ResultMessage } = await forcePasswordResetRequest(formData);
      if (ResultMessage === "Request Successful") {
        authMethods.updateLoggedInUser({ ...loggedInUser, ResetPswd: "0" });

        toast.success("Your password has been reset.", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
        navigate("/");
      }
    } catch (error) {
      toast.error("We were not able to reset your password. Try again.", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="mt-10">
          <label className="block mt-5">
            <div className="flex">
              <span className="text-black block text-sm font-light mb-2">
                Password
              </span>
              <span className="text-red-600">&nbsp;*</span>
            </div>
            <input
              type="password"
              placeholder="Password"
              className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
              "
              {...register("Password", {
                required: {
                  value: true,
                  message: "Please provide a strong password.",
                },
                minLength: {
                  value: 8,
                  message: "Password should be 8 or more characters",
                },
              })}
            />
            <span className="text-os-primary block text-sm mt-2 -mb-4">
              {errors?.Password?.message ?? ""}
            </span>
          </label>
        </div>
        <div className="mt-10">
          <label className="block mt-5">
            <div className="flex">
              <span className="text-black block text-sm font-light mb-2">
                Confirm New Password
              </span>
              <span className="text-red-600">&nbsp;*</span>
            </div>
            <input
              type="password"
              placeholder="Confirm New Password"
              className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
              "
              {...register("ConfirmPassword", {
                required: {
                  value: true,
                  message: "Please confirm your new password.",
                },
                validate: (val: string) => {
                  if (watch("Password") !== val) {
                    return "Your passwords do not match";
                  }
                },
              })}
            />
            <span className="text-os-primary block text-sm mt-2 -mb-4">
              {errors?.ConfirmPassword?.message ?? ""}
            </span>
          </label>
        </div>

        <div className="mt-10">
          <CTAbtn
            type="submit"
            text={isSubmitting ? "CHANGING..." : "CHANGE PASSWORD"}
            disabled={isSubmitting}
            color="os-btn-third"
          />
        </div>
      </form>
    </>
  );
};
