import React from "react";
import { useForm } from "react-hook-form";
import { CTAbtn } from "../form/Buttons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPasswordRequest } from "../../api/auth/resetPasswordRequest";
import { toast } from "react-toastify";

interface ResetPasswordFormValues {
  UserToken: string | null;
  Password: string;
  ConfirmPassword: string;
}

export const ResetPasswordForm = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  let navigate = useNavigate();

  const defaultValues: Partial<ResetPasswordFormValues> = {
    UserToken: token,
    Password: "",
    ConfirmPassword: "",
  };

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    register,
    watch,
    setError,
  } = useForm<ResetPasswordFormValues>({ defaultValues });

  const onSubmit = async (formData: ResetPasswordFormValues) => {
    try {
      const { ResultMessage } = await resetPasswordRequest(formData);
      if (ResultMessage === "Request Successful") {
        toast.success("Your password has been reset successfully!", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
        navigate("/");
      } else {
        setError(
          "Password",
          {
            type: "custom",
            message: ResultMessage,
          },
          { shouldFocus: true }
        );
        toast.error("We could not reset your password. Please try again.", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    } catch (error) {}
    return;
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="mb-10">
          <label className="block mt-5">
            <div className="flex">
              <span className="text-white block text-sm font-light mb-2">
                New Password
              </span>
              <span className="text-white">&nbsp;*</span>
            </div>

            <input
              type="password"
              placeholder="New Password"
              className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
              "
              {...register("Password", {
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters.",
                },
                required: {
                  value: true,
                  message: "Please enter a strong password.",
                },
              })}
            />
            <span className="text-os-primary block text-sm mt-2 -mb-4">
              {errors?.Password?.message ?? ""}
            </span>
          </label>
        </div>
        <div className="mb-10">
          <label className="block mt-5">
            <div className="flex">
              <span className="text-white block text-sm font-light mb-2">
                Confirm New Password
              </span>
              <span className="text-white">&nbsp;*</span>
            </div>

            <input
              type="password"
              placeholder="Confirm New Password"
              className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
              "
              {...register("ConfirmPassword", {
                required: {
                  value: true,
                  message: "Please confirm your new password.",
                },
                validate: (val: string) => {
                  if (watch("Password") !== val) {
                    return "Your passwords do not match";
                  }
                },
              })}
            />
            <span className="text-os-primary block text-sm mt-2 -mb-4">
              {errors?.ConfirmPassword?.message ?? ""}
            </span>
          </label>
        </div>

        <div className="mt-4">
          <CTAbtn
            type="submit"
            text={isSubmitting ? "RESETING..." : "RESET PASSWORD"}
            disabled={isSubmitting}
            color="os-btn-primary"
          />
        </div>
      </form>
    </>
  );
};
