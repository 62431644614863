import React from "react";
import { UseFormRegister } from "react-hook-form";
import clsx from "clsx";

interface InputProps {
  label?: string;
  name: string;
  type?: string;
  placeholder?: string;
  register: UseFormRegister<any>;
  error: string | undefined;
  labelClass: string;
  errorClass: string;
  disable?: boolean;
  required?: boolean;
}

export const InputBlock = (props: InputProps) => {
  const {
    label,
    type,
    name,
    placeholder,
    register,
    error,
    errorClass,
    labelClass,
    disable,
    required,
    ...rest
  } = props;
  return (
    <label className="block mt-5">
      <div className="flex">
        <span className={clsx(labelClass, "block text-sm font-light mb-2")}>
          {label}
        </span>
        {required && <span className="text-red-600">&nbsp;*</span>}
      </div>

      <input
        type={type}
        placeholder={placeholder}
        className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
            focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
            "
        {...register(name)}
        disabled={disable}
        {...rest}
      />
      <span className={clsx(errorClass, "block text-sm mt-2 -mb-4")}>
        {error ?? ""}
      </span>
    </label>
  );
};

interface SearchInputProps {
  label?: string;
  name: string;
  type?: string;
  placeholder?: string;
  register: UseFormRegister<any>;
  error: string | undefined;
  labelClass: string;
  errorClass: string;
  disable?: boolean;
  required?: boolean;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchInputBlock = (props: SearchInputProps) => {
  const {
    label,
    type,
    name,
    placeholder,
    register,
    error,
    errorClass,
    labelClass,
    disable,
    required,
    ...rest
  } = props;
  return (
    <label className="block mt-5">
      <div className="flex">
        <span className={clsx(labelClass, "block text-sm font-light mb-2")}>
          {label}
        </span>
        {required && <span className="text-red-600">&nbsp;*</span>}
      </div>
      <div className="relative">
        <input
          type={type}
          placeholder={placeholder}
          className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
            focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
            "
          {...register(name, {min:4})}
          disabled={disable}
          {...rest}
        />
        <i className="fa-light fa-magnifying-glass absolute top-3 right-3"></i>
      </div>
      <span className={clsx(errorClass, "block text-sm mt-2 -mb-4")}>
        {error ?? ""}
      </span>
    </label>
  );
};

interface SelectProps {
  value: string;
  label?: string;
  name: string;
  type?: string;
  placeholder?: string;
  register: UseFormRegister<any>;
  error: string | undefined;
  labelClass: string;
  errorClass: string;
  disable?: boolean;
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  children?: JSX.Element | JSX.Element[];
}

export const SelectBlock = (props: SelectProps) => {
  const {
    label,
    type,
    name,
    register,
    error,
    errorClass,
    labelClass,
    disable,
    required,
    value,
    onChange,
    ...rest
  } = props;
  return (
    <label className="block mt-5">
      <div className="flex">
        <span className={clsx(labelClass, "block text-sm font-light mb-2")}>
          {label}
        </span>
        {required && <span className="text-red-600">&nbsp;*</span>}
      </div>

      <select
        value={value || ''}
        onChange={onChange}
        className="bg-white w-full border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block p-2.5"

        {...rest}
      >
        {props.children}
      </select>
      <span className={clsx(errorClass, "block text-sm mt-2 -mb-4")}>
        {error ?? ""}
      </span>
    </label>
  );
};

interface CheckboxProps {
  label?: string;
  name: string;
  type?: string;
  placeholder?: string;
  register: UseFormRegister<any>;
  error: string | undefined;
}

export const inputClass = "block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200";
export const labelClass = "text-sm font-light mt-7 mb-2";
export const CheckboxBlock = (props: CheckboxProps) => {
  // @ts-ignore
  const { label, type, name, placeholder, register, ...rest } = props;
  return (
    <div className="flex items-start">
      <div className="flex items-center h-5">
        <input
          id="remember"
          type={type}
          className="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300"
          {...register(name)}
          {...rest}
        />
      </div>
      <label className="ml-2 text-sm text-white">{props.label}</label>
    </div>
  );
};
