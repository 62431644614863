import React from "react";
import altCompanyImg from "../assets/alts/company-alt.png";
import altProfileImg from "../assets/alts/profile-alt.png";
import { ForcePasswordResetForm } from "../components/myaccount/ForcePasswordResetForm";
import { PageMeta } from "../components/shared/PageMeta";
import { useAuthContext } from "../context/AuthContext";
import { LoggedInUser } from "../interfaces/LoggedInUser";

function getAvatar(loggedInUser: LoggedInUser) {
  if (loggedInUser?.UserLevel === "Agent") {
    return loggedInUser.Photo || altProfileImg;
  }
  return loggedInUser.TerritoryRepPhoto || altCompanyImg;
}

interface IProps {
  children: React.ReactNode | string | null;
}
export default function ForcePasswordReset(props: IProps) {
  const [authState, authActions] = useAuthContext();
  const { loggedInUser } = authState;

  const handleLogout = () => {
    authActions.signOut();
  };

  if (!loggedInUser) {
    throw new Error(
      "This component should be accessible by logged in user only."
    );
  }
  if (loggedInUser.ResetPswd !== "1") {
    return <>{props.children}</>;
  }

  return (
    <>
      <PageMeta title="Password Reset" />

      {/* body of the page */}
      <main className="w-full">
        {/* -------- */}

        {/* -------- */}
        <section className="">
          <div className="relative w-full h-full overflow-y-auto">
            <div className="flex flex-col items-center w-2/3 lg:w-1/3 md:w-1/2 mx-auto">
              <img
                className="mt-14 w-40 h-40 rounded-full object-cover mb-10"
                src={getAvatar(loggedInUser)}
                alt="OneSource Solutions"
              />

              <h1 className="text-4xl font-light mb-8 text-center">
                Password Reset
              </h1>

              <div className="block w-full mb-10 xl:px-20 lg:px-15 md:px-10">
                <p>Dear {loggedInUser.FirstName},</p>

                <p>
                  We're committed to ensuring your account's security. We kindly
                  request that you reset your password. This additional step
                  will help us safeguard your account and data.
                </p>

                <ForcePasswordResetForm />

                <hr className="mt-10" />
              </div>

              <div className="px-5">
                <button
                  type="button"
                  className="text-sm font-bold text-os-text-link underline hover:underline"
                  onClick={handleLogout}
                >
                  Log Out
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
