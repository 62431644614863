import { Request } from "../Request";

export async function getUserDetailsRequest(
  PortalToken: string | null | undefined,
  UserLevel: string | null | undefined,
  OfficeToken?: string | null | undefined,
  AgentToken?: string | null | undefined
) {
  const data = {
    PortalToken,
    UserLevel,
  };
  if (OfficeToken !== "" && OfficeToken !== null) {
    data["OfficeToken"] = OfficeToken;
  }
  if (AgentToken !== "" && AgentToken !== null) {
    data["AgentToken"] = AgentToken;
  }

  const response = await Request("get-user-details", data);
  return response.data;
}
