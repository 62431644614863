import React from "react";
import { useForm } from "react-hook-form";
import { CTAbtn } from "../form/Buttons";
import { useAuthContext } from "../../context/AuthContext";
import { changePasswordRequest } from "../../api/myaccount/changePasswordRequest";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface AccountFormValues {
  PortalToken: string | null | undefined;
  UserLevel: string | null | undefined;
  Password: string;
  NewPassword: string;
  ConfirmPassword: string;
}

export const ChangePasswordForm = () => {
  const [authState] = useAuthContext();
  const { loggedInUser } = authState;
  const navigate = useNavigate();

  const defaultValues: Partial<AccountFormValues> = {
    PortalToken: loggedInUser?.PortalToken,
    UserLevel: loggedInUser?.UserLevel,
    Password: "",
    NewPassword: "",
    ConfirmPassword: "",
  };

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    register,
    watch,
  } = useForm<AccountFormValues>({ defaultValues });

  const onSubmit = async (formData: AccountFormValues) => {
    try {
      const { ResultMessage } = await changePasswordRequest(formData);
      if (ResultMessage === "Request Successful") {
        toast.success("Your account has been updated.", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
        navigate("/myAccount");
      } else if (ResultMessage === "Current Password is Invalid") {
        toast.error("Your have entered a wrong current password.", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    } catch (error) {}
    return;
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <label className="block mt-5">
          <div className="flex">
            <span className="text-black block text-sm font-light mb-2">
              Current Password
            </span>
            <span className="text-red-600">&nbsp;*</span>
          </div>
          <input
            type="password"
            placeholder="Current Password"
            className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
              "
            {...register("Password", {
              required: {
                value: true,
                message: "Current password is required.",
              },
            })}
          />
          <span className="text-os-primary block text-sm mt-2 -mb-4">
            {errors?.Password?.message ?? ""}
          </span>
        </label>
        <div className="mt-10">
          <label className="block mt-5">
            <div className="flex">
              <span className="text-black block text-sm font-light mb-2">
                New Password
              </span>
              <span className="text-red-600">&nbsp;*</span>
            </div>
            <input
              type="password"
              placeholder="New Password"
              className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
              "
              {...register("NewPassword", {
                required: {
                  value: true,
                  message: "Please provide a strong password.",
                },
                minLength: {
                  value: 8,
                  message: "Password should be 8 or more characters",
                },
              })}
            />
            <span className="text-os-primary block text-sm mt-2 -mb-4">
              {errors?.NewPassword?.message ?? ""}
            </span>
          </label>
        </div>
        <div className="mt-10">
          <label className="block mt-5">
            <div className="flex">
              <span className="text-black block text-sm font-light mb-2">
                Confirm New Password
              </span>
              <span className="text-red-600">&nbsp;*</span>
            </div>
            <input
              type="password"
              placeholder="Confirm New Password"
              className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
              "
              {...register("ConfirmPassword", {
                required: {
                  value: true,
                  message: "Please confirm your new password.",
                },
                validate: (val: string) => {
                  if (watch("NewPassword") !== val) {
                    return "Your passwords do not match";
                  }
                },
              })}
            />
            <span className="text-os-primary block text-sm mt-2 -mb-4">
              {errors?.ConfirmPassword?.message ?? ""}
            </span>
          </label>
        </div>

        <div className="mt-10">
          <CTAbtn
            type="submit"
            text={isSubmitting ? "CHANGING..." : "CHANGE PASSWORD"}
            disabled={isSubmitting}
            color="os-btn-third"
          />
        </div>
      </form>
    </>
  );
};
