import React, { ReactElement } from "react";
import { Link, useLocation } from "react-router-dom";
import { LoggedInUser } from "../../interfaces/LoggedInUser";

interface NavPagesBtnsProps {
  loggedInUser: LoggedInUser | null;
}
export default function NavPagesBtns(props: NavPagesBtnsProps): ReactElement {
  const location = useLocation();
  const { pathname } = location;
  const { loggedInUser } = props;
  return (
    <>
      <Link
        to={"/"}
        className={`flex flex-col justify-around py-2 px-8 items-center border-b-4 border-${
          pathname === "/" ? "os-primary" : "transparent"
        }`}
      >
        <i
          className={`${
            pathname === "/" ? "fas" : "far"
          } fa-home text-white text-xl`}
        />
        <span className="text-white text-sm">Dashboard</span>
      </Link>
      <Link
        to={"/clients"}
        className={`flex flex-col justify-around py-2 px-8 items-center border-b-4 border-${
          pathname === "/clients" ? "os-primary" : "transparent"
        }`}
      >
        <i
          className={`${
            pathname === "/clients" ? "fas" : "far"
          } fa-user text-white text-xl`}
        />
        <span className="text-white text-sm">Clients/Leads</span>
      </Link>
      {loggedInUser?.UserLevel !== "Agent" && (
        <Link
          to={"/agents"}
          className={`flex flex-col justify-around py-2 px-8 items-center border-b-4 border-${
            pathname === "/agents" ? "os-primary" : "transparent"
          }`}
        >
          <i
            className={`${
              pathname === "/agents" ? "fas" : "far"
            } fa-user-cog text-white text-xl`}
          />
          <span className="text-white text-sm">Agents</span>
        </Link>
      )}
      {loggedInUser?.UserLevel === "Corporate" && (
        <Link
          to={"/offices"}
          className={`flex flex-col justify-around py-2 px-8 items-center border-b-4 border-${
            pathname === "/offices" ? "os-primary" : "transparent"
          }`}
        >
          <i
            className={`${
              pathname === "/offices" ? "fas" : "far"
            } fa-building text-white text-xl`}
          />
          <span className="text-white text-sm">Offices</span>
        </Link>
      )}
    </>
  );
}
