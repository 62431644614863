import React, { ReactElement, useMemo, useState, useCallback } from "react";
import { useQuery } from "react-query";
import { getServiceActivityRequest } from "../../api/dashboard/getServiceActivityRequest";
import { LoggedInUser } from "../../interfaces/LoggedInUser";
import DataBlock from "../../components/dashboard/DataBlock";
import { format, startOfMonth, startOfYear, startOfWeek } from "date-fns";

interface StatsProps {
  loggedInUser: LoggedInUser | null;
  OfficeToken?: string | null | undefined;
  AgentToken?: string | null | undefined;
}

export default function ClientsStats(props: StatsProps): ReactElement {
  const { loggedInUser, OfficeToken, AgentToken } = props;
  const [clientType, setClientType] = useState("total");

  const query = useQuery(["get-monthly-stats", OfficeToken, AgentToken], () =>
    getServiceActivityRequest(
      loggedInUser?.PortalToken,
      loggedInUser?.UserLevel,
      "",
      "",
      AgentToken,
      OfficeToken
    )
  );
  const { data, isLoading } = query;
  const weekClient = useMemo(() => {
    return clientType === "total"
      ? data?.ClientsThisWeek
      : data?.SoldThisWeek ?? 0;
  }, [data, clientType]);

  const totalClients = useMemo(() => {
    return clientType === "total"
      ? data?.TotalClients
      : data?.TotalClientsSold ?? 0;
  }, [data, clientType]);

  const ytdClients = useMemo(() => {
    if (isLoading) {
      return 0;
    }
    let i = 0;
    clientType === "total"
      ? data?.ClientsByMonth?.forEach((month) => {
          if (typeof month?.NumClients === "string") {
            i += parseFloat(month?.NumClients);
          }
        })
      : (i = data?.SoldInDateRange);
    return i || 0;
  }, [data, isLoading, clientType]);

  const currentMonth = useMemo(() => {
    if (isLoading) {
      return 0;
    }
    return clientType === "total"
      ? data?.ClientsByMonth[data?.ClientsByMonth?.length - 1]?.NumClients
      : data?.SoldByMonth[data?.SoldByMonth?.length - 1]?.NumClients ?? 0;
  }, [data, isLoading, clientType]);

  const toggleClientType = useCallback(() => {
    clientType === "total" ? setClientType("sold") : setClientType("total");
  }, [clientType]);
  const firstDayOfYear = format(startOfYear(new Date()), "yyyy-MM-dd");
  const firstDayOfMonth = format(startOfMonth(new Date()), "yyyy-MM-dd");
  const firstDayOfWeek = format(startOfWeek(new Date()), "yyyy-MM-dd");
  const today = format(new Date(), "yyyy-MM-dd");

  return (
    <div className="flex flex-col w-full mb-10">
      <div className="flex flex-col sm:flex-row mb-6 ">
        <span className="text-xl font-light tracking-wider">CLIENTS</span>
        <div className="toggleSlider flex items-center sm:ml-5">
          <div className="off-state-label mr-2 text-xs">Total</div>

          <label className="flex items-center cursor-pointer">
            <div className="relative">
              <input
                type="checkbox"
                className="sr-only"
                disabled={false}
                defaultChecked={clientType === "sold"}
                onChange={() => toggleClientType()}
              />
              <div className="slider block bg-gray-10 border border-black w-6 h-3 rounded-full"></div>
              <div className="dot absolute left-1 top-0.5 bg-black w-2 h-2 rounded-full transition"></div>
            </div>
          </label>
          <div className="on-state-label ml-2 text-xs">Sold</div>
        </div>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-5 ">
        <DataBlock
          linkTo={"/clients"}
          filters={{
            ...(clientType === "sold" && {
              SoldOnly: "1",
            }),
          }}
          title="Total Clients"
          num={totalClients}
          isLoading={isLoading}
        />
        <DataBlock
          linkTo={"/clients"}
          filters={{
            StartDate: firstDayOfYear,
            EndDate: today,
            DateField: clientType === "total" ? "DateAdded" : "OrderDate",
            ...(clientType === "sold" && {
              SoldOnly: "1",
            }),
          }}
          title="YTD New Clients"
          num={ytdClients}
          isLoading={isLoading}
        />
        <DataBlock
          linkTo={"/clients"}
          filters={{
            StartDate: firstDayOfMonth,
            EndDate: today,
            DateField: clientType === "total" ? "DateAdded" : "OrderDate",
            ...(clientType === "sold" && {
              SoldOnly: "1",
            }),
          }}
          title="Monthly New Clients"
          num={currentMonth}
          isLoading={isLoading}
        />
        <DataBlock
          linkTo={"/clients"}
          filters={{
            StartDate: firstDayOfWeek,
            EndDate: today,
            DateField: clientType === "total" ? "DateAdded" : "OrderDate",
            ...(clientType === "sold" && {
              SoldOnly: "1",
            }),
          }}
          title="Weekly New Clients"
          num={weekClient}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}
