import { Request } from "../Request";

export async function getClientsListRequest(
  PortalToken: string | null | undefined,
  UserLevel: string | null | undefined,
  ReturnRows?: number | null,
  PageNumber?: number | null,
  SearchType?: string | null,
  SearchValue?: string | null,
  SortValue?: string | null,
  SortDirection?: string | null,
  StartDate?: string | null | undefined,
  EndDate?: string | null | undefined,
  DateField?: string | null | undefined,
  statusFilter?: string | null,
  SoldOnly?: string | null,
  ShowLost?: string | null,
  Services?: string[] | null
) {
  const data = {
    PortalToken,
    UserLevel,
    ReturnRows,
    PageNumber,
    SortValue,
    SortDirection,
    StartDate,
    EndDate,
    DateField,
  };

  if (SearchType && SearchValue && SearchValue.length >= 4) {
    data["SearchType"] = SearchType;
    data["SearchValue"] = SearchValue;
  }
  if (statusFilter) {
    data["Status"] = statusFilter;
  }

  if (ShowLost) {
    data["ShowLost"] = "1";
  }

  if (SoldOnly) {
    data["SoldOnly"] = "1";
  }

  if (Services) {
    data["Services"] = Services;
  }
  const response = await Request("get-customer-list", data);
  return response.data;
}
