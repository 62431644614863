import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getServiceActivityRequest } from "../../api/dashboard/getServiceActivityRequest";
import { LoggedInUser } from "../../interfaces/LoggedInUser";
import DataBlock from "./DataBlock";
import InlineLoader from "../shared/InlineLoader";
import MonthRangePickerModal from "../shared/MonthRangePickerModal";
import { format, startOfMonth, parse } from "date-fns";

interface PremiumServicesStatsProps {
  loggedInUser: LoggedInUser | null;
  OfficeToken?: string | null | undefined;
  AgentToken?: string | null | undefined;
}

export default function PremiumServicesStats(
  props: PremiumServicesStatsProps
): ReactElement {
  const { loggedInUser, AgentToken, OfficeToken } = props;
  const today = format(new Date(), "yyyy-MM-dd");
  const firstDayOfMonth = format(startOfMonth(new Date()), "yyyy-MM-dd");
  const [beginDate, setBeginDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(today);
  const [serviceType, setServiceType] = useState("sales");
  const query = useQuery(
    ["get-premium-services-stats", beginDate, endDate, AgentToken, OfficeToken],
    () =>
      getServiceActivityRequest(
        loggedInUser?.PortalToken,
        loggedInUser?.UserLevel,
        beginDate,
        endDate,
        AgentToken,
        OfficeToken
      )
  );

  const { data, isLoading } = query;
  const servicesSales = useMemo(() => {
    const services = data?.PaidServices ?? {};
    const servicesAry = Object.entries(services).sort();
    return servicesAry;
  }, [data]);

  const servicesInstalls = useMemo(() => {
    const services = data?.Installed ?? {};
    const servicesAry = Object.entries(services).sort();
    return servicesAry;
  }, [data]);

  const services = useMemo(() => {
    return serviceType === "sales" ? servicesSales : servicesInstalls;
  }, [serviceType, servicesSales, servicesInstalls]);

  const utilities = useMemo(() => {
    const utilities = data?.Utilities ?? {};
    delete utilities["Sewer"];
    delete utilities["Trash"];
    const utilitiesAry = Object.entries(utilities).sort();
    return utilitiesAry;
  }, [data]);

  const handleMonthRangeApply = (begin, end) => {
    setBeginDate(begin);
    setEndDate(end);
  };

  const toggleServiceType = useCallback(() => {
    serviceType === "sales"
      ? setServiceType("installs")
      : setServiceType("sales");
  }, [serviceType]);

  const dateFieldFilter = useMemo(() => {
    return serviceType === "sales" ? "OrderDate" : "InstallationDate";
  }, [serviceType]);

  const currentDate = new Date();
  const maxYear = currentDate.getUTCFullYear() + 1;
  return (
    <>
      <div className="flex flex-col w-full mb-10">
        <div className="flex justify-between items-center mb-6">
          <div className="flex flex-col sm:flex-row">
            <span className="text-xl font-light tracking-wider">
              PREMIUM SERVICES
            </span>
            <div className="toggleSlider flex items-center sm:ml-5">
              <div className="off-state-label mr-2 text-xs">Sales</div>

              <label className="flex items-center cursor-pointer">
                <div className="relative">
                  <input
                    type="checkbox"
                    className="sr-only"
                    disabled={false}
                    defaultChecked={serviceType === "installs"}
                    onChange={() => toggleServiceType()}
                  />
                  <div className="slider block bg-gray-10 border border-black w-6 h-3 rounded-full"></div>
                  <div className="dot absolute left-1 top-0.5 bg-black w-2 h-2 rounded-full transition"></div>
                </div>
              </label>
              <div className="on-state-label ml-2 text-xs">Installs</div>
            </div>
          </div>
          <MonthRangePickerModal
            initialBeginDate={parse(beginDate, "yyyy-MM-dd", new Date())}
            initialEndDate={parse(endDate, "yyyy-MM-dd", new Date())}
            minYear={2013}
            maxYear={maxYear}
            handleApply={handleMonthRangeApply}
          />
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-4 gap-5 ">
          {!isLoading &&
            services.map((service: any, index) => (
              <DataBlock
                linkTo={`/clients`}
                filters={{
                  Services: service[0],
                  StartDate: beginDate,
                  EndDate: endDate,
                  DateField: dateFieldFilter,
                  ShowLost: "1",
                }}
                key={index}
                title={service[0]}
                num={service[1]}
                isLoading={false}
              />
            ))}
          {isLoading && <InlineLoader />}
        </div>
      </div>
      <div className="flex flex-col w-full mb-10">
        <span className="text-xl font-light mb-6 tracking-wider">
          UTILITIES
        </span>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-5 ">
          {!isLoading &&
            utilities.map((service: any, index) => (
              <DataBlock
                linkTo={`/clients`}
                filters={{
                  Services: service[0],
                  StartDate: beginDate,
                  EndDate: endDate,
                  DateField: dateFieldFilter,
                  ShowLost: "1",
                }}
                key={index}
                title={service[0]}
                num={service[1]}
                isLoading={false}
              />
            ))}
          {isLoading && <InlineLoader />}
        </div>
      </div>
    </>
  );
}
