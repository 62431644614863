import React from "react";
import { useForm } from "react-hook-form";
import { CTAbtn } from "../form/Buttons";
import { forgotPasswordRequest } from "../../api/auth/forgotPasswordRequest";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface ForgotPasswordFormValues {
  Email: string;
  ReturnURL: string;
}

const defaultValues: Partial<ForgotPasswordFormValues> = {
  Email: "",
  ReturnURL: process.env.REACT_APP_URL + "resetpassword",
};

export const ForgotPasswordForm = () => {
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    register,
    setError,
  } = useForm<ForgotPasswordFormValues>({ defaultValues });
  let navigate = useNavigate();

  const onSubmit = async (formData: ForgotPasswordFormValues) => {
    try {
      const { ResultMessage } = await forgotPasswordRequest(formData);
      if (ResultMessage === "Request Successful") {
        toast.success(
          "We sent a password reset email to you! Please use the reset link to reset your password.",
          {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          }
        );
        navigate("/");
      } else {
        toast.error(
          "Something went wrong! Please enter the email address you have used for your account.",
          {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          }
        );
        setError(
          "Email",
          {
            type: "custom",
            message: ResultMessage,
          },
          { shouldFocus: true }
        );
      }
    } catch (error) {}
    return;
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="mb-10">
          <label className="block mt-5">
            <div className="flex">
              <span className="text-white block text-sm font-light mb-2">
                Confirm Email Address
              </span>
              <span className="text-white">&nbsp;*</span>
            </div>

            <input
              type="text"
              placeholder="Email Address"
              className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
              "
              {...register("Email", {
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address.",
                },
                required: {
                  value: true,
                  message: "A valid email address is required.",
                },
              })}
            />
            <span className="text-os-primary block text-sm mt-2 -mb-4">
              {errors?.Email?.message ?? ""}
            </span>
          </label>
        </div>

        <div className="mt-4">
          <CTAbtn
            type="submit"
            text={isSubmitting ? "SENDING..." : "SEND PASSWORD RESET EMAIL"}
            disabled={isSubmitting}
            color="os-btn-primary"
          />
        </div>
      </form>
    </>
  );
};
