import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import NavPagesBtns from "../dashboard/NavPagesBtns";
import { LoggedInUser } from "../../interfaces/LoggedInUser";

type Anchor = "left";

interface AgentDetailsDrawerProps {
  icon: string | any;
  loggedInUser: LoggedInUser | null;
}

export default function MenuDrawer(
  props: AgentDetailsDrawerProps
): React.ReactElement | any {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  return (
    <div>
      <React.Fragment key={"left"}>
        <span
          onClick={toggleDrawer("left", true)}
          className="text-sm font-medium cursor-pointer"
        >
          {props.icon}
        </span>
        <Drawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
        >
          <Box
            sx={{ width: 200 }}
            role="presentation"
            onClick={toggleDrawer("left", false)}
            onKeyDown={toggleDrawer("left", false)}
          >
            <i className="absolute top-5 right-5 cursor-pointer fas fa-times text-white"></i>
          </Box>
          <div className="fixed top-0 left-0 h-full app-default-bg pt-20 px-10  overflow-auto scroll-p-0">
            <div className="grid grid-cols-1 gap-10 w-full">
              <NavPagesBtns loggedInUser={props.loggedInUser} />
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
