import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { CTAbtn } from "../form/Buttons";
import { useForm } from "react-hook-form";
import Tooltip from "@mui/material/Tooltip";
import altProfileImg from "../../assets/alts/profile-alt.png";
import InlineLoader from "../shared/InlineLoader";
import { getAgentsListRequest } from "../../api/agents/getAgentsListRequest";
import { getOfficesListRequest } from "../../api/offices/getOfficesListRequest";
import { LoggedInUser } from "../../interfaces/LoggedInUser";
import Pagination from "../../components/shared/Pagination";

interface SearchFormValues {
  SearchType: string;
  SearchValue: string;
}

const defaultValues: Partial<SearchFormValues> = {
  SearchType: "Agent",
  SearchValue: "",
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <i className="fa-regular fa-times text-lg"></i>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface GlobalSearchProps {
  loggedInUser: LoggedInUser | null;
  buttonType: string;
  setAgentDetailsOpen: (value: boolean) => void;
  setAgentDetails: (value) => void;
  setOfficeDetailsOpen: (value: boolean) => void;
  setOfficeDetails: (value) => void;
}

export default function GlobalSearch(props: GlobalSearchProps) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [SearchType, setSearchType] = useState("");
  const [SearchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);

  const {
    buttonType,
    loggedInUser,
    setAgentDetailsOpen,
    setAgentDetails,
    setOfficeDetailsOpen,
    setOfficeDetails,
  } = props;

  const query = useQuery(
    ["get-search-list", page, perPage, SearchValue, SearchType],
    () => {
      if (SearchType === "Agent") {
        return getAgentsListRequest(
          loggedInUser?.PortalToken,
          loggedInUser?.UserLevel,
          perPage,
          page + 1,
          "Name",
          SearchValue
        );
      } else {
        return getOfficesListRequest(
          loggedInUser?.PortalToken,
          loggedInUser?.UserLevel,
          perPage,
          page + 1,
          "OfficeName",
          SearchValue
        );
      }
    },

    { enabled: !!SearchValue }
  );
  const { data, isSuccess, refetch, fetchStatus } = query;

  useEffect(() => {
    if (isSuccess) {
      setTotal(parseInt(data.TotalResults, 10));
    }
  }, [data, isSuccess]);
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    getValues,
  } = useForm<SearchFormValues>({ defaultValues });

  const handleSearch = async () => {
    try {
      setSearchType(getValues("SearchType"));
      setSearchValue(getValues("SearchValue"));
      setLoading(true);
      await refetch();
      setLoading(false);
    } catch (error) {}
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleReset = async () => {
    reset(defaultValues);
    setSearchType(getValues("SearchType"));
    setSearchValue(getValues("SearchValue"));
    setPage(0);
    setPerPage(10);
    // setResetLoading(true);
    // setResetLoading(false);
  };
  const handleClose = async () => {
    setOpen(false);
    reset(defaultValues);
    setSearchType(getValues("SearchType"));
    setSearchValue(getValues("SearchValue"));
    setResetLoading(true);
    await refetch();
    setResetLoading(false);
  };

  const openAgentDetailsDrawer = (agent) => {
    setAgentDetails(agent);
    setAgentDetailsOpen(true);
    setOpen(false);
  };
  const openOfficeDetailsDrawer = (office) => {
    setOfficeDetails(office);
    setOfficeDetailsOpen(true);
    setOpen(false);
  };
  return (
    <div>
      {buttonType === "icon" && (
        <button
          onClick={handleClickOpen}
          className="hidden md:flex flex-col justify-around items-center ml-5"
        >
          <Tooltip title="Search">
            <i className="fas fa-search text-white text-xl"></i>
          </Tooltip>
        </button>
      )}
      {buttonType === "CTA" && (
        <CTAbtn
          type="button"
          text="SEARCH"
          color="os-btn-secondary"
          onClick={handleClickOpen}
        />
      )}

      {/* </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Global Lookup
        </BootstrapDialogTitle>
        <form onSubmit={handleSubmit(handleSearch)} noValidate>
          <DialogContent dividers>
            <div className="flex flex-col min-w-[300px]">
              <label className="block mt-5">
                <div className="flex">
                  <span className="text-black block text-sm font-light mb-2">
                    Search By
                  </span>
                </div>

                <select
                  className="bg-white w-full border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block p-[9.5px]"
                  {...register("SearchType", {
                    required: {
                      value: true,
                      message: "Required.",
                    },
                  })}
                >
                  <option value="Agent">Agent</option>
                  {loggedInUser?.UserLevel === "Corporate" && (
                    <option value="Office">Office</option>
                  )}
                </select>
              </label>
              <label className="block mt-5">
                <div className="flex">
                  <span className="text-black block text-sm font-light mb-2">
                    Name
                  </span>
                </div>

                <input
                  type="text"
                  placeholder="Name"
                  className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
                                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
                                "
                  {...register("SearchValue", {
                    required: {
                      value: true,
                      message: "Name is required.",
                    },
                    minLength: {
                      value: 4,
                      message: "Please enter at least 4 characters.",
                    },
                  })}
                />
                <span className="text-os-primary block text-sm mt-2 -mb-4">
                  {errors?.SearchValue?.message ?? ""}
                </span>
              </label>
              <div className="flex flex-col w-full mt-8">
                {data && SearchValue !== "" && (
                  <span className="font-light mb-5">SEARCH RESULTS</span>
                )}
                {data?.ResultCode === 201 && (
                  <span className="mb-5">No results found.</span>
                )}
                {loading && fetchStatus !== "idle" ? (
                  <div className="flex justify-center items-center gap-2">
                    <InlineLoader />
                  </div>
                ) : null}
                {/*  */}
                {SearchType === "Office" &&
                  data?.Offices &&
                  !loading &&
                  SearchValue !== "" &&
                  data?.Offices.map((office: any, index) => (
                    <div
                      key={index}
                      className="flex flex-col w-full mb-5 cursor-pointer hover:shadow-md"
                      onClick={() => {
                        openOfficeDetailsDrawer(office);
                      }}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <img
                            src={altProfileImg}
                            alt="Profile"
                            className="w-10 h-10 rounded-full object-cover"
                          />
                          <div className="flex flex-col justify-between ml-4">
                            <span className="text-sm">
                              {office?.OfficeName}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {SearchType === "Agent" &&
                  data?.Agents &&
                  !loading &&
                  SearchValue !== "" &&
                  data?.Agents.map((agent: any, index) => (
                    <div
                      key={index}
                      className="flex flex-col w-full mb-5 cursor-pointer hover:shadow-md"
                      onClick={() => {
                        openAgentDetailsDrawer(agent);
                      }}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <img
                            src={
                              agent?.Photo !== "" && agent?.Photo !== null
                                ? agent?.Photo
                                : altProfileImg
                            }
                            alt="Profile"
                            className="w-10 h-10 rounded-full object-cover"
                          />
                          <div className="flex flex-col justify-between ml-4">
                            <span className="text-sm">
                              {agent?.FirstName} {agent?.LastName}
                            </span>
                            <span className="text-sm font-light">
                              Office: {agent?.Company}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {SearchValue !== "" && data?.TotalResults > 10 && (
                  <Pagination
                    perPage={perPage}
                    page={page}
                    onPageChange={setPage}
                    onPerPageChange={setPerPage}
                    total={total}
                  />
                )}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex flex-col w-full p-2">
              <CTAbtn
                type="reset"
                disabled={resetLoading}
                text={
                  resetLoading ? (
                    <div className="flex justify-center items-center gap-2">
                      Resetting... <InlineLoader />
                    </div>
                  ) : (
                    <span>Reset Filters</span>
                  )
                }
                color="os-btn-third"
                reversed={true}
                onClick={handleReset}
              />
              <div className="my-3">
                <CTAbtn
                  type="submit"
                  disabled={loading && fetchStatus !== "idle"}
                  text={
                    loading && fetchStatus !== "idle" ? (
                      <div className="flex justify-center items-center gap-2">
                        Searching... <InlineLoader />
                      </div>
                    ) : (
                      <span>Search</span>
                    )
                  }
                  color="os-btn-third"
                />
              </div>
            </div>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}
