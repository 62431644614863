import React, { ReactElement } from "react";
import altProfileImg from "../../assets/alts/profile-alt.png";

interface ProfileBlockProps {
  name: any;
  title?: string;
  url: string;
  onClick?: () => void;
}

export default function ProfileBlock(props: ProfileBlockProps): ReactElement {
  return (
    <div className="flex flex-col w-full" onClick={props.onClick}>
      <div className="flex">
        <div className="flex items-center">
          <img
            src={
              props.url !== "" && props.url !== null && props.url !== undefined
                ? props.url
                : altProfileImg
            }
            alt="Profile"
            className="w-10 h-10 rounded-full object-cover"
          />
          <div className="flex flex-col justify-between ml-4">
            <span className="text-sm">{props.name}</span>
            <span className="text-sm font-light">{props.title}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
