import React from "react";
import TablePagination from "@mui/material/TablePagination";

interface PaginationProps {
  page: number;
  perPage: number;
  total: number | undefined;
  onPageChange: (page: number) => void;
  onPerPageChange: (per: number) => void;
}

export default function Pagination(props: PaginationProps) {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    props.onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    props.onPerPageChange(parseInt(event.target.value, 10));
    props.onPageChange(0);
  };

  return (
    <TablePagination
      component="div"
      count={props.total || 0}
      page={props.page}
      onPageChange={handleChangePage}
      rowsPerPage={props.perPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}
