import React from 'react';
import { AuthProvider } from "./AuthContext";
import { FilterProvider } from "./FilterContext";

interface ContextProviderProps {
  children: React.ReactNode;
}

export const ContextProvider = (props: ContextProviderProps) => {
  return(
    <AuthProvider>
      <FilterProvider>
        {props.children}
      </FilterProvider>
    </AuthProvider>
  );
}