import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";

interface PageMetaProps {
  title: string;
  children?: ReactNode;
}

export const PageMeta = (props: PageMetaProps) => {
  const { title, children } = props;
  return (
    <Helmet>
      <title>
        {title}
        {title && " - "}OneSource - Partner Portal
      </title>
      {children}
    </Helmet>
  );
};
