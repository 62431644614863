import { Request } from "../Request";

type FormData = {
  PortalToken: string | null | undefined;
  UserLevel: string | null | undefined;
  ContactName: string | null | undefined;
};

export async function corporateAccountUpdateRequest(formData: FormData) {
  const { PortalToken, UserLevel, ContactName } = formData;
  const response = await Request("user-account-update", {
    PortalToken,
    UserLevel,
    ContactName,
  });
  return response.data;
}
