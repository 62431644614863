import React, { createContext, useReducer, useMemo, useEffect } from "react";
import { LoggedInUser } from "../interfaces/LoggedInUser";

type AuthAction =
  | {
      type: "SIGN_IN";
      payload: { remember: boolean; loggedInUser: LoggedInUser };
    }
  | { type: "SIGN_OUT" }
  | { type: "UPDATE_LOGGED_IN_USER"; payload: LoggedInUser };

export interface Auth {
  loggedInUser: LoggedInUser | null;
  remember: boolean;
}

type IAuthContext = [
  Auth,
  {
    signIn: (loggedInUser: LoggedInUser, remember: boolean) => void;
    signOut: () => void;
    updateLoggedInUser: (loggedInUser: LoggedInUser) => void;
  }
];

function authReducer(state: Auth, action: AuthAction) {
  switch (action.type) {
    case "SIGN_IN":
      return {
        ...state,
        loggedInUser: action.payload.loggedInUser,
        remember: action.payload.remember,
      };
    case "UPDATE_LOGGED_IN_USER":
      return { ...state, loggedInUser: action.payload };
    case "SIGN_OUT":
      return {
        ...state,
        loggedInUser: null,
      };
    default:
      return state;
  }
}

const LOGGED_IN_USER_KEY = "os_portal_logged_in_user";
const REMEMBER_ME_KEY = "os_portal_remember_me";

const AuthContext = createContext<IAuthContext>({} as IAuthContext);
let loggedInUser = null;
let remember = false;
let stored = localStorage.getItem(LOGGED_IN_USER_KEY);
if (!stored) {
  stored = sessionStorage.getItem(LOGGED_IN_USER_KEY);
}
if (stored) {
  loggedInUser = JSON.parse(stored);
  remember = localStorage.getItem(REMEMBER_ME_KEY) === "true";
}

const initialState: Auth = {
  loggedInUser,
  remember: remember,
};

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = (props: AuthProviderProps) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const { loggedInUser, remember } = state;

  useEffect(() => {
    if (loggedInUser) {
      if (remember) {
        localStorage.setItem(LOGGED_IN_USER_KEY, JSON.stringify(loggedInUser));
      } else {
        sessionStorage.setItem(
          LOGGED_IN_USER_KEY,
          JSON.stringify(loggedInUser)
        );
      }
      localStorage.setItem(REMEMBER_ME_KEY, remember.toString());
    } else {
      localStorage.removeItem(LOGGED_IN_USER_KEY);
      sessionStorage.removeItem(LOGGED_IN_USER_KEY);
      localStorage.removeItem(REMEMBER_ME_KEY);
    }
  }, [loggedInUser, remember]);

  const handlers = useMemo(
    () => ({
      signIn: (value: LoggedInUser, remember: boolean) => {
        const payload = { loggedInUser: value, remember };
        dispatch({ type: "SIGN_IN", payload });
      },
      signOut: () => dispatch({ type: "SIGN_OUT" }),
      updateLoggedInUser: (value: LoggedInUser) =>
        dispatch({ type: "UPDATE_LOGGED_IN_USER", payload: value }),
    }),
    []
  );

  return (
    <AuthContext.Provider value={[state, handlers]}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => React.useContext(AuthContext);
