import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Customer } from "../../interfaces/Customer";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { slashDate } from "../../helpers/dateTime";
import { singleAddress } from "../../helpers/string";

type Anchor = "right";

interface AgentDetailsDrawerProps {
  client: Customer | undefined;
  open: boolean;
  setOpen: (val: boolean) => void;
}

export default function ClientDetailsDrawer(
  props: AgentDetailsDrawerProps
): React.ReactElement | any {
  const { client, open, setOpen } = props;
  const [, setState] = React.useState({
    right: open,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(open);
    };
  useEffect(() => {
    setState((state) => {
      return { ...state, right: open };
    });
  }, [open]);

  const containerStyle = {
    width: "400px",
    height: "300px",
  };

  const center = {
    lat: parseFloat(client?.Latitude || ""),
    lng: parseFloat(client?.Longitude || ""),
  };

  return (
    <div>
      {client && (
        <React.Fragment key={"right"}>
          <Drawer
            anchor={"right"}
            open={open}
            onClose={toggleDrawer("right", false)}
          >
            <Box
              sx={{ width: 400 }}
              role="presentation"
              onClick={toggleDrawer("right", false)}
              onKeyDown={toggleDrawer("right", false)}
            >
              <i className="absolute top-5 right-5 cursor-pointer fas fa-times"></i>
            </Box>
            <div className="flex flex-col max-w-sm ">
              <section className="flex flex-col p-5">
                <span className="text-2xl mb-5">
                  {client.LastName}
                  {client.LastName ? ", " : ""}
                  {client.FirstName}
                </span>
                <span className="font-light text-md mb-5">
                  {singleAddress(client)}
                </span>
                <span className="text-sm font-light mb-2">
                  Client ID: {client.CustomerID}
                </span>
                <span className="text-sm font-light">
                  Closing Date: {client.ClosingDate}
                </span>
              </section>
              <div className="w-full">
                {/* {!isLoaded && <InlineLoader />} */}
                {
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={11}
                  >
                    <MarkerF position={center}></MarkerF>
                  </GoogleMap>
                }
              </div>
              <section className="flex flex-col w-full p-5">
                <div>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<i className="fas fa-chevron-down"></i>}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text-lg">
                        Premium Services ({client.PaidServices.length})
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      {client.PaidServices.map((p, i) => (
                        <div
                          key={i}
                          className="grid grid-cols-[1fr_1fr] gap-2 gap-y-7 w-full py-3 px-2 mb-5 border rounded-lg shodow hover:shadow-md"
                        >
                          <div className="flex flex-col ">
                            <span className="text-xs mb-1">Type</span>
                            <span className="text-sm font-light">
                              {p.Service}
                            </span>
                          </div>
                          <div className="flex flex-col text-left">
                            <span className="text-xs mb-1">Provider</span>

                            <span className="text-sm font-light">
                              {p.Provider}
                            </span>
                          </div>
                          <div className="flex flex-col col-span-2">
                            <span className="text-xs mb-1">Order Date</span>
                            <span className="text-sm font-light">
                              {p.OrderDate === "" || p.OrderDate === null
                                ? "00/00/0000"
                                : slashDate(p.OrderDate)}
                            </span>
                          </div>
                          <div className="flex flex-col ">
                            <span className="text-xs mb-2">
                              Completion Date
                            </span>
                            {p.CompletionDate !== null &&
                            p.CompletionDate !== "" ? (
                              <span className="text-sm font-light">
                                {slashDate(p.CompletionDate)}
                              </span>
                            ) : (
                              <span className="text-white w-fit text-xs uppercase bg-os-status-Pending rounded-full text-center px-5 py-1">
                                PENDING
                              </span>
                            )}
                          </div>

                          <div className="flex flex-col text-left">
                            <span className="text-xs mb-2">Status</span>

                            <span
                              className={`text-white w-fit text-xs uppercase bg-os-status-${p.OrderStatus.split(
                                " "
                              ).join("")} rounded-full text-center px-5 py-1`}
                            >
                              {p.OrderStatus}
                            </span>
                          </div>
                          <div className="flex flex-col col-span-2">
                            <span className="text-xs mb-1">Order Notes</span>
                            <span className="text-sm font-light">
                              There is no Note for individual services.
                            </span>
                          </div>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<i className="fas fa-chevron-down"></i>}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span className="text-lg">
                        Utilities ({client.Utilities.length})
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      {client.Utilities.map((u, i) => (
                        <div
                          key={i}
                          className="grid grid-cols-[1fr_1fr] gap-2 gap-y-7 w-full py-3 px-2 mb-5 border rounded-lg shodow hover:shadow-md"
                        >
                          <div className="flex flex-col ">
                            <span className="text-xs mb-1">Type</span>
                            <span className="text-sm font-light">
                              {u.Service}
                            </span>
                          </div>
                          <div className="flex flex-col text-left">
                            <span className="text-xs mb-1">Provider</span>

                            <span className="text-sm font-light">
                              {u.Provider}
                            </span>
                          </div>
                          <div className="flex flex-col col-span-2">
                            <span className="text-xs mb-1">Order Date</span>
                            <span className="text-sm font-light">
                              {u.OrderDate === "" || u.OrderDate === null
                                ? "00/00/0000"
                                : slashDate(u.OrderDate)}
                            </span>
                          </div>
                          <div className="flex flex-col ">
                            <span className="text-xs mb-2">
                              Completion Date
                            </span>
                            {u.CompletionDate !== null &&
                            u.CompletionDate !== "" ? (
                              <span className="text-sm font-light">
                                {slashDate(u.CompletionDate)}
                              </span>
                            ) : (
                              <span className="text-white w-fit text-xs uppercase bg-os-status-Pending rounded-full text-center px-5 py-1">
                                PENDING
                              </span>
                            )}
                          </div>

                          <div className="flex flex-col text-left">
                            <span className="text-xs mb-2">Status</span>

                            <span
                              className={`text-white w-fit text-xs uppercase bg-os-status-${u.OrderStatus.split(
                                " "
                              ).join("")} rounded-full text-center px-5 py-1`}
                            >
                              {u.OrderStatus}
                            </span>
                          </div>
                          <div className="flex flex-col col-span-2">
                            <span className="text-xs mb-1">Order Notes</span>
                            <span className="text-sm font-light">
                              There is no Note for individual services.
                            </span>
                          </div>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              </section>
            </div>
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
}
