import { Request } from "../Request";

type Credentials = {
  Email: string;
  ReturnURL: string;
};

export async function forgotPasswordRequest(credentials: Credentials) {
  const { Email, ReturnURL } = credentials;

  const response = await Request("forgot-password-start", { Email, ReturnURL });

  return response.data;
}
