import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import altProfileImg from "../../assets/alts/profile-alt.png";
import { Agent } from "../../interfaces/Agent";
import { slashDate } from "../../helpers/dateTime";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";

type Anchor = "right";

interface AgentDetailsDrawerProps {
  agent: Agent | undefined;
  open: boolean;
  setOpen: (val: boolean) => void;
}

export default function AgentDetailsDrawer(
  props: AgentDetailsDrawerProps
): React.ReactElement | any {
  const { agent, open, setOpen } = props;
  const [, setState] = React.useState({
    right: open,
  });
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(open);
    };

  useEffect(() => {
    setState((state) => {
      return { ...state, right: open };
    });
  }, [open]);

  return (
    <div>
      {agent && (
        <React.Fragment key={"right"}>
          <Drawer
            anchor={"right"}
            open={open}
            onClose={toggleDrawer("right", false)}
          >
            <Box
              sx={{ width: 400 }}
              role="presentation"
              onClick={toggleDrawer("right", false)}
              onKeyDown={toggleDrawer("right", false)}
            >
              <i className="absolute top-5 right-5 cursor-pointer fas fa-times"></i>
            </Box>
            <div className="flex flex-col max-w-sm ">
              <div className="flex justify-center py-9 w-full">
                <img
                  src={
                    agent.Photo !== "" && agent.Photo !== null
                      ? agent.Photo
                      : altProfileImg
                  }
                  alt="Profile"
                  className="w-40 h-40 rounded-full object-cover"
                />
              </div>
            </div>
            <section className="flex flex-col w-full justify-center items-center">
              <span className="text-3xl mb-5">
                {agent.LastName}, {agent.FirstName}
              </span>
              <span className="font-light text-sm">
                Office: {agent.Company}
              </span>
              <div className="flex p-10">
                <Link to={`/`} state={{ agentToken: agent.AgentToken }}>
                  <Tooltip title="View As">
                    <i className="fas fa-eye"></i>
                  </Tooltip>
                </Link>
                <a href={`tel:`} className="ml-5">
                  <Tooltip title="Call">
                    <i className="fas fa-phone"></i>
                  </Tooltip>
                </a>
                <a href={`mailto:`} className="ml-5">
                  <Tooltip title="Email">
                    <i className="fas fa-envelope"></i>
                  </Tooltip>
                </a>
              </div>
              <span className="font-light text-sm mb-3">Member Since</span>
              <span className="text-sm font-bold mb-10">
                {slashDate(agent.DateAdded)}
              </span>
              <span className="font-light text-sm mb-3">Leads Submitted</span>
              <span className="text-sm font-bold mb-10">
                {agent.LeadsSubmitted}
              </span>
              <span className="font-light text-sm mb-3">Leads Sold</span>
              <span className="text-sm font-bold mb-10">{agent.LeadsSold}</span>
            </section>
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
}
