import React, { useState, useEffect, useCallback } from "react";
import { useQuery } from "react-query";
import Nav from "../components/dashboard/Nav";
import Header from "../components/dashboard/Header";
import { useAuthContext } from "../context/AuthContext";
import Pagination from "../components/shared/Pagination";
import OfficeBlock from "../components/dashboard/OfficeBlock";
import SearchCtaBtnsForMobile from "../components/dashboard/SearchCtaBtnsForMobile";
import { getOfficesListRequest } from "../api/offices/getOfficesListRequest";
import InlineLoader from "../components/shared/InlineLoader";
import OfficesSearch from "../components/modals/OfficesSearch";
import OfficeDetailsDrawer from "../components/modals/OfficeDetails";
import { PageMeta } from "../components/shared/PageMeta";
import clsx from "clsx";
import Tooltip from "@mui/material/Tooltip";

export default function Offices() {
  const [authState] = useAuthContext();
  const { loggedInUser } = authState;
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [total, setTotal] = useState(0);
  const [searchType, setSearchType] = useState("Name");
  const [searchValue, setSearchValue] = useState("");
  const [officeDetails, setOfficeDetails] = useState();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [sortValue, setSortValue] = useState("DateAdded");
  const [sortDir, setSortDir] = useState("Down");
  const query = useQuery(
    [
      "get-offices-list",
      page,
      perPage,
      searchType,
      searchValue,
      sortValue,
      sortDir,
    ],
    () =>
      getOfficesListRequest(
        loggedInUser?.PortalToken,
        loggedInUser?.UserLevel,
        perPage,
        page + 1,
        searchType,
        searchValue,
        sortValue,
        sortDir
      )
  );
  const { data, isSuccess, isLoading, refetch } = query;
  const sortByDate = useCallback(
    (value) => {
      sortDir === "Down" ? setSortDir("Up") : setSortDir("Down");
      setSortValue(value);
    },
    [sortDir]
  );

  useEffect(() => {
    if (isSuccess) {
      setTotal(parseInt(data.TotalResults, 10));
    }
  }, [data, isSuccess]);

  return (
    <>
      <PageMeta title="Offices" />
      <Nav loggedInUser={loggedInUser} />
      {/* body of the page */}
      <main className="w-full">
        {/* CTA in mobile screen */}
        <SearchCtaBtnsForMobile loggedInUser={loggedInUser} />
        {/* -------- */}
        <Header title="Offices" loggedInUser={loggedInUser} />
        {/* -------- */}
        <section className="flex flex-col w-full p-5 md:p-10">
          <div className="hidden lg:flex flex-col w-full border rounded-lg">
            <div className="flex w-full justify-between p-5">
              <span className="font-light">All Offices</span>
              <div className="flex ">
                {!isLoading && (
                  <a href={`${data?.ExportURL}`} download="export.csv">
                    <Tooltip title="Download">
                      <i className="fas fa-download pl-5"></i>
                    </Tooltip>
                  </a>
                )}
                <OfficesSearch
                  searchValue={searchValue}
                  searchType={searchType}
                  setSearchValue={setSearchValue}
                  setSearchType={setSearchType}
                  refetch={refetch}
                />
              </div>
            </div>
            <title className="grid grid-cols-[3fr_1fr_1fr_1fr_1fr_0.5fr] gap-5 border-b p-5 items-center">
              <div>
                <span className="text-sm font-light">Office Name</span>
                <i
                  className={clsx(`fas ml-2 text-sm cursor-pointer`, {
                    "fa-caret-down":
                      sortDir === "Up" || sortValue !== "OfficeName",
                    "fa-caret-up":
                      sortDir !== "Up" && sortValue === "OfficeName",
                  })}
                  onClick={() => sortByDate("OfficeName")}
                ></i>
              </div>
              <div>
                <span className="text-sm font-light">Leads Added</span>
                <i
                  className={clsx(`fas ml-2 text-sm cursor-pointer`, {
                    "fa-caret-down":
                      sortDir === "Up" || sortValue !== "LeadsSubmitted",
                    "fa-caret-up":
                      sortDir !== "Up" && sortValue === "LeadsSubmitted",
                  })}
                  onClick={() => sortByDate("LeadsSubmitted")}
                ></i>
              </div>
              <span className="text-sm font-light">Monthly Revenue</span>
              <div>
                <span className="text-sm font-light">YTD Revenue</span>
                <i
                  className={clsx(`fas ml-2 text-sm cursor-pointer`, {
                    "fa-caret-down":
                      sortDir === "Up" || sortValue !== "YTDRevenue",
                    "fa-caret-up":
                      sortDir !== "Up" && sortValue === "YTDRevenue",
                  })}
                  onClick={() => sortByDate("YTDRevenue")}
                ></i>
              </div>
              <span className="text-sm font-light">Revenue Sharing</span>
            </title>
            {!isLoading && (
              <OfficeBlock
                device="desktop"
                offices={data?.Offices}
                loggedInUser={loggedInUser}
                setOfficeDetails={setOfficeDetails}
                setDetailsOpen={setDetailsOpen}
              />
            )}
            {isLoading && (
              <div className="flex w-full justify-center p-20">
                <InlineLoader />
              </div>
            )}
            {!isLoading && !data?.Offices && (
              <div className="flex w-full justify-center p-20">
                <span>No results found</span>
              </div>
            )}
            {!isLoading && data?.Offices && (
              <Pagination
                perPage={perPage}
                page={page}
                onPageChange={setPage}
                onPerPageChange={setPerPage}
                total={total}
              />
            )}
          </div>
          {/* mobile device */}
          <div className="flex lg:hidden flex-col w-full ">
            {!isLoading && (
              <OfficeBlock
                device="mobile"
                offices={data?.Offices}
                loggedInUser={loggedInUser}
                setOfficeDetails={setOfficeDetails}
                setDetailsOpen={setDetailsOpen}
              />
            )}

            {isLoading && (
              <div className="flex w-full justify-center p-20">
                <InlineLoader />
              </div>
            )}
            {!isLoading && !data?.Offices && (
              <div className="flex w-full justify-center p-20">
                <span>No results found</span>
              </div>
            )}
            {!isLoading && (
              <Pagination
                perPage={perPage}
                page={page}
                onPageChange={setPage}
                onPerPageChange={setPerPage}
                total={total}
              />
            )}
          </div>
          <OfficeDetailsDrawer
            office={officeDetails}
            open={detailsOpen}
            setOpen={setDetailsOpen}
          />
        </section>
      </main>
    </>
  );
}
