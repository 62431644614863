import { Request } from "../Request";

type FormData = {
  PortalToken: string | null | undefined;
  UserLevel: string | undefined;
  FirstName: string |  undefined;
  LastName: string | undefined;
};

export async function agentAccountUpdateRequest(formData: FormData) {
  const { PortalToken, UserLevel, FirstName, LastName } = formData;
  const response = await Request("user-account-update", {
    PortalToken,
    UserLevel,
    FirstName,
    LastName,
  });
  return response.data;
}
