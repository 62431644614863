import React, {ReactElement} from "react";
import { ToastContainer } from "react-toastify";
import { Portal } from "react-portal";

interface AppToastsProps {
  children: ReactElement | null;
}
const AppToasts = (props:AppToastsProps) => (
  <>
    {props.children}
    <Portal>
      <ToastContainer position="bottom-center" />
    </Portal>
  </>
);

export default AppToasts;
