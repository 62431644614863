import { Request } from "../Request";

type Credentials = {
  UserToken: string | null;
  Password: string;
};

export async function resetPasswordRequest(credentials: Credentials) {
  const { UserToken, Password } = credentials;

  const response = await Request("update-password", { UserToken, Password });

  return response.data;
}
