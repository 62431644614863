import React, { ReactElement } from "react";
import { useQuery } from "react-query";
import altProfileImg from "../../assets/alts/profile-alt.png";
import altCompanyImg from "../../assets/alts/company-alt.png";
import { LoggedInUser } from "../../interfaces/LoggedInUser";
import Tooltip from "@mui/material/Tooltip";
import { getUserDetailsRequest } from "../../api/dashboard/getUserDetailsRequest";
import { Link } from "react-router-dom";

interface HeaderProps {
  title: string;
  loggedInUser: LoggedInUser | null;
  OfficeToken?: string | null | undefined;
  AgentToken?: string | null | undefined;
}

export default function Header(props: HeaderProps): ReactElement {
  const { title, loggedInUser, OfficeToken, AgentToken } = props;

  const query = useQuery(
    ["get-user-details-for-header", OfficeToken, AgentToken],
    () =>
      getUserDetailsRequest(
        loggedInUser?.PortalToken,
        loggedInUser?.UserLevel,
        OfficeToken,
        AgentToken
      )
  );
  const { data: details } = query;

  return (
    <header className="flex flex-col-reverse sm:flex-row justify-between w-full px-5 sm:px-10 pt-4 sm:pt-7">
      <div className="flex flex-col mt-5 sm:mt-0">
        <span className="text-sm">Welcome, {loggedInUser?.Company}</span>
        <h1 className="text-4xl font-light">{title}</h1>
      </div>
      <div className="flex flex-col">
        {OfficeToken || AgentToken ? (
          <div className="flex justify-end align-middle w-full">
            <Link
              to={"/"}
              state={{ agentToken: "", officeToken: "" }}
              className="flex justify-center bg-red-500 rounded px-4 mr-4"
            >
              <span className="text-sm text-white">Exit View As Mode</span>
              <i className="fa-light fa-xmark ml-4 text-white mt-[2px]"></i>
            </Link>
            <span className="text-right text-sm">Viewing As</span>
          </div>
        ) : (
          <span className="text-right text-sm">
            My Territory Representative
          </span>
        )}

        {AgentToken && (
          <div className="flex items-center">
            <img
              src={details?.Photo !== "" ? details?.Photo : altProfileImg}
              alt="Profile"
              className="w-10 h-10 rounded-full object-cover"
            />
            <span className="ml-4">
              {details?.FirstName} {details?.LastName}
            </span>
          </div>
        )}
        {OfficeToken && (
          <div className="flex items-center">
            <img
              src={details?.Logo !== "" ? details?.Logo : altCompanyImg}
              alt="Profile"
              className="w-10 h-10 rounded-full object-cover"
            />
            <span className="ml-4">{details?.Company}</span>
          </div>
        )}
        {!AgentToken && !OfficeToken ? (
          <div className="flex items-center justify-between sm:justify-end">
            <div className="flex items-center">
              <img
                src={
                  loggedInUser?.TerritoryRepPhoto !== ""
                    ? loggedInUser?.TerritoryRepPhoto
                    : altProfileImg
                }
                alt="Profile"
                className="w-10 h-10 rounded-full object-cover"
              />
              <span className="ml-4">{loggedInUser?.TerritoryRepName}</span>
            </div>

            <div className="flex">
              <a
                href={`tel:${loggedInUser?.TerritoryRepPhone}`}
                className="ml-5"
              >
                <Tooltip title="Call">
                  <i className="fas fa-phone"></i>
                </Tooltip>
              </a>
              <a
                href={`mailto:${loggedInUser?.TerritoryRepEmail}`}
                className="ml-5"
              >
                <Tooltip title="Email">
                  <i className="fas fa-envelope"></i>
                </Tooltip>
              </a>
            </div>
          </div>
        ) : null}
      </div>
    </header>
  );
}
