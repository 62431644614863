import React from "react";

interface InlineLoaderProps {
  className?: string;
}

const InlineLoader = React.forwardRef<HTMLDivElement, InlineLoaderProps>(
  (props, ref) => {
    const { className } = props;
    const wrapperClass = `inline-block relative opacity-30 ${className ?? ""}`;
    return (
      <div className={wrapperClass}>
        <div className="spinner w-6 h-6 text-black">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
);

InlineLoader.displayName = "InlineLoader";

export default InlineLoader;
