import React, { useState, useEffect, useCallback } from "react";
import { useQuery } from "react-query";
import Nav from "../components/dashboard/Nav";
import Header from "../components/dashboard/Header";
import { useAuthContext } from "../context/AuthContext";
import Pagination from "../components/shared/Pagination";
import AgentBlock from "../components/dashboard/AgentBlock";
import SearchCtaBtnsForMobile from "../components/dashboard/SearchCtaBtnsForMobile";
import { getAgentsListRequest } from "../api/agents/getAgentsListRequest";
import InlineLoader from "../components/shared/InlineLoader";
import AgentsSearch from "../components/modals/AgentsSearch";
import AgentDetailsDrawer from "../components/modals/AgentDetails";
import clsx from "clsx";
import Tooltip from "@mui/material/Tooltip";

export default function Agents() {
  const [authState] = useAuthContext();
  const { loggedInUser } = authState;
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [total, setTotal] = useState(0);
  const [searchType, setSearchType] = useState("Name");
  const [searchValue, setSearchValue] = useState("");
  const [agentDetails, setAgentDetails] = useState();
  const [sortValue, setSortValue] = useState("DateAdded");
  const [sortDir, setSortDir] = useState("Down");
  const [detailsOpen, setDetailsOpen] = useState(false);

  const query = useQuery(
    [
      "get-agents-list",
      page,
      perPage,
      searchType,
      searchValue,
      sortValue,
      sortDir,
    ],
    () =>
      getAgentsListRequest(
        loggedInUser?.PortalToken,
        loggedInUser?.UserLevel,
        perPage,
        page + 1,
        searchType,
        searchValue,
        sortValue,
        sortDir
      )
  );
  const { data, isSuccess, isLoading, refetch } = query;
  const sortByDate = useCallback(
    (value) => {
      sortDir === "Down" ? setSortDir("Up") : setSortDir("Down");
      setSortValue(value);
    },
    [sortDir]
  );

  useEffect(() => {
    if (isSuccess) {
      setTotal(parseInt(data.TotalResults, 10));
    }
  }, [data, isSuccess]);

  return (
    <>
      <Nav loggedInUser={loggedInUser} />
      {/* body of the page */}
      <main className="w-full">
        {/* CTA in mobile screen */}
        <SearchCtaBtnsForMobile loggedInUser={loggedInUser} />
        {/* -------- */}
        <Header title="Agents" loggedInUser={loggedInUser} />
        {/* -------- */}
        {/* <div className="flex md:hidden justify-around w-full p-5 pb-0">
          <div className="w-full">
            <CTAbtn
              type="button"
              text="FILTERS"
              color="os-btn-third"
              reversed={true}
            />
          </div>
        </div> */}
        {/* -------- */}
        <section className="flex flex-col w-full p-5 md:p-10">
          <div className="hidden lg:flex flex-col w-full border rounded-lg">
            <div className="flex w-full justify-between p-5">
              <span className="font-light">All Agents</span>
              <div className="flex">
                <AgentsSearch
                  searchValue={searchValue}
                  searchType={searchType}
                  setSearchValue={setSearchValue}
                  setSearchType={setSearchType}
                  refetch={refetch}
                />
                {!isLoading && (
                  <a href={`${data?.ExportURL}`} download="export.csv">
                    <Tooltip title="Download">
                      <i className="fas fa-download pl-5"></i>
                    </Tooltip>
                  </a>
                )}
                {/* <button>
                  <i className="fas fa-filter pl-5"></i>
                </button> */}
              </div>
            </div>
            <title className="grid grid-cols-[3fr_1fr_1fr_1fr_0.5fr] gap-5 border-b p-5 items-center">
              <div>
                <span className="text-sm font-light">Agent/Office Name</span>
                <i
                  className={clsx(`fas ml-2 text-sm cursor-pointer`, {
                    "fa-caret-down":
                      sortDir === "Up" || sortValue !== "LastName",
                    "fa-caret-up": sortDir !== "Up" && sortValue === "LastName",
                  })}
                  onClick={() => sortByDate("LastName")}
                ></i>
              </div>

              <div>
                <span className="text-sm font-light">Date Started</span>
                <i
                  className={clsx(`fas ml-2 text-sm cursor-pointer`, {
                    "fa-caret-down":
                      sortDir === "Up" || sortValue !== "DateAdded",
                    "fa-caret-up":
                      sortDir !== "Up" && sortValue === "DateAdded",
                  })}
                  onClick={() => sortByDate("DateAdded")}
                ></i>
              </div>
              <div>
                <span className="text-sm font-light">Clients Submitted</span>{" "}
                <i
                  className={clsx(`fas ml-2 text-sm cursor-pointer`, {
                    "fa-caret-down":
                      sortDir === "Up" || sortValue !== "LeadsSubmitted",
                    "fa-caret-up":
                      sortDir !== "Up" && sortValue === "LeadsSubmitted",
                  })}
                  onClick={() => sortByDate("LeadsSubmitted")}
                ></i>
              </div>
              <div>
                <span className="text-sm font-light">Clients Assisted</span>
                <i
                  className={clsx(`fas ml-2 text-sm cursor-pointer`, {
                    "fa-caret-down":
                      sortDir === "Up" || sortValue !== "LeadsSold",
                    "fa-caret-up":
                      sortDir !== "Up" && sortValue === "LeadsSold",
                  })}
                  onClick={() => sortByDate("LeadsSold")}
                ></i>
              </div>
            </title>
            {!isLoading && (
              <AgentBlock
                device="desktop"
                agents={data?.Agents}
                setAgentDetails={setAgentDetails}
                setDetailsOpen={setDetailsOpen}
              />
            )}
            {isLoading && (
              <div className="flex w-full justify-center p-20">
                <InlineLoader />
              </div>
            )}
            {!isLoading && !data?.Agents && (
              <div className="flex w-full justify-center p-20">
                <span>No results found</span>
              </div>
            )}
            {!isLoading && data?.Agents && (
              <Pagination
                perPage={perPage}
                page={page}
                onPageChange={setPage}
                onPerPageChange={setPerPage}
                total={total}
              />
            )}
          </div>
          {/* mobile device */}
          <div className="flex lg:hidden flex-col w-full ">
            {!isLoading && (
              <AgentBlock
                device="mobile"
                agents={data?.Agents}
                setAgentDetails={setAgentDetails}
                setDetailsOpen={setDetailsOpen}
              />
            )}
            {isLoading && (
              <div className="flex w-full justify-center p-20">
                <InlineLoader />
              </div>
            )}
            {!isLoading && !data?.Agents && (
              <div className="flex w-full justify-center p-20">
                <span>No results found</span>
              </div>
            )}
            {/* pagination */}
            {!isLoading && (
              <Pagination
                perPage={perPage}
                page={page}
                onPageChange={setPage}
                onPerPageChange={setPerPage}
                total={total}
              />
            )}
          </div>
          <AgentDetailsDrawer
            agent={agentDetails}
            open={detailsOpen}
            setOpen={setDetailsOpen}
          />
        </section>
      </main>
    </>
  );
}
