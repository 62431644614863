import { Request } from "../Request";

type FormData = {
  PortalToken: string | null | undefined;
  UserLevel: string | null | undefined;
  Photo: string;
};

export async function profilePhotoUploadRequest(formData: FormData) {
  const { PortalToken, UserLevel, Photo } = formData;
  const response = await Request("agent-photo", {
    PortalToken,
    UserLevel,
    Photo,
  });
  return response;
}
