import { Request } from "../Request";

export async function getCommissionsRequest(
  PortalToken: string | null | undefined,
  UserLevel: string | null | undefined,
  StartDate?: string | null,
  EndDate?: string | null,
  OfficeToken?: string | null,
  AgentToken?: string | null
) {
  const data = {
    PortalToken,
    UserLevel,
    StartDate,
    EndDate,
  };

  if (AgentToken !== "" && AgentToken !== null) {
    data["AgentToken"] = AgentToken;
  }

  if (OfficeToken !== "" && OfficeToken !== null) {
    data["OfficeToken"] = OfficeToken;
  }
  const response = await Request("get-commissions", data);

  return response.data;
}
