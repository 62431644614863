import { Request } from "../Request";

type FormData = {
  PortalToken: string | null | undefined;
  UserLevel: string | null | undefined;
  Password: string;
  NewPassword: string;
  ConfirmPassword: string;
};

export async function changePasswordRequest(formData: FormData) {
  const { PortalToken, UserLevel, Password, NewPassword } = formData;
  const response = await Request("user-account-update", {
    PortalToken,
    UserLevel,
    Password,
    NewPassword,
  });
  return response.data;
}
