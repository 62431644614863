import React, { useState } from "react";
import { useQuery } from "react-query";
import Nav from "../components/dashboard/Nav";
import Header from "../components/dashboard/Header";
import SectionDivider from "../components/dashboard/SectionDivider";
import MonthlyClientsChart from "../components/charts/MonthlyClientsChart";
import { useAuthContext } from "../context/AuthContext";
import RevenueSection from "../components/dashboard/RevenueSection";
import ClientsStats from "../components/dashboard/ClientsStats";
import PremiumServicesStats from "../components/dashboard/PremiumServicesStats";
import SearchCtaBtnsForMobile from "../components/dashboard/SearchCtaBtnsForMobile";
import AgentDetailsDrawer from "../components/modals/AgentDetails";
import OfficeDetailsDrawer from "../components/modals/OfficeDetails";
import { useLocation } from "react-router-dom";
import { PageMeta } from "../components/shared/PageMeta";
import { getUserDetailsRequest } from "../api/dashboard/getUserDetailsRequest";

export default function Dashboard() {
  const [authState] = useAuthContext();
  const { loggedInUser } = authState;
  const [officeDetails, setOfficeDetails] = useState();
  const [officeDetailsOpen, setOfficeDetailsOpen] = useState(false);
  const [agentDetails, setAgentDetails] = useState();
  const [agentDetailsOpen, setAgentDetailsOpen] = useState(false);

  const locationState =
    (useLocation().state as {
      agentToken: string | null | undefined;
      officeToken: string | null | undefined;
    }) ||
    ({ agentToken: "", officeToken: "" } as {
      agentToken: string | null | undefined;
      officeToken: string | null | undefined;
    });
  const { agentToken = "", officeToken = "" } = locationState;
  const query = useQuery(["get-user-details-for-dashboard"], () =>
    getUserDetailsRequest(
      loggedInUser?.PortalToken,
      loggedInUser?.UserLevel,
      officeToken,
      agentToken
    )
  );
  const { data: details } = query;

  return (
    <>
      <PageMeta title="Dashboard" />
      <Nav loggedInUser={loggedInUser} />
      {/* body of the page */}
      <main className="w-full">
        {/* CTA in mobile screen */}
        <SearchCtaBtnsForMobile loggedInUser={loggedInUser} />
        {/* -------- */}
        <Header
          title="Dashboard"
          loggedInUser={loggedInUser}
          OfficeToken={officeToken}
          AgentToken={agentToken}
        />
        {/* -------- */}
        <section className="flex flex-col w-full p-5 md:p-20">
          <ClientsStats
            loggedInUser={loggedInUser}
            OfficeToken={officeToken}
            AgentToken={agentToken}
          />
          {/* graph section */}
          <MonthlyClientsChart
            height={440}
            loggedInUser={loggedInUser}
            OfficeToken={officeToken}
            AgentToken={agentToken}
          />

          <SectionDivider />
          {/* ------ */}
          <PremiumServicesStats
            loggedInUser={loggedInUser}
            OfficeToken={officeToken}
            AgentToken={agentToken}
          />
          {/* ----- */}
          <SectionDivider />
          {(loggedInUser?.UserLevel === "Office" &&
            details?.RevenueSharing === "1") ||
          loggedInUser?.UserLevel === "Corporate" ? (
            <RevenueSection
              loggedInUser={loggedInUser}
              setAgentDetails={setAgentDetails}
              setAgentDetailsOpen={setAgentDetailsOpen}
              setOfficeDetails={setOfficeDetails}
              setOfficeDetailsOpen={setOfficeDetailsOpen}
              OfficeToken={officeToken}
              AgentToken={agentToken}
            />
          ) : null}
          <AgentDetailsDrawer
            agent={agentDetails}
            open={agentDetailsOpen}
            setOpen={setAgentDetailsOpen}
          />
          <OfficeDetailsDrawer
            office={officeDetails}
            open={officeDetailsOpen}
            setOpen={setOfficeDetailsOpen}
          />
        </section>
      </main>
      <footer className="w-full h-5 app-default-bg"></footer>
    </>
  );
}
