import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { CTAbtn } from "../form/Buttons";
import { useForm } from "react-hook-form";
import { useAuthContext } from "../../context/AuthContext";
import { newReferralRequest } from "../../api/agents/newReferralRequest";
import { STATE_DICTIONARY } from "../../constants/stateDictionary";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ReferralFormValues {
  PortalToken: string | null | undefined;
  UserLevel: string | null | undefined;
  FirstName: string;
  LastName: string;
  Phone: string;
  Email: string;
  AptNumber: string;
  Address: string;
  City: string;
  State: string;
  Zip: string;
  ClosingDate: string;
  Notes: string;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <i className="fa-regular fa-times text-lg"></i>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function ReferralForm() {
  const [open, setOpen] = React.useState(false);

  const [authState] = useAuthContext();
  const { loggedInUser } = authState;
  const defaultValues: Partial<ReferralFormValues> = {
    PortalToken: loggedInUser?.PortalToken,
    UserLevel: loggedInUser?.UserLevel,
    FirstName: "",
    LastName: "",
    Phone: "",
    Email: "",
    AptNumber: "",
    Address: "",
    City: "",
    State: "",
    Zip: "",
    ClosingDate: "",
    Notes: "",
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };
  const [, setPhone] = useState();

  const handlePhoneNumber = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPhone(value);
  };
  const [state, setState] = useState("");

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    register,
    reset,
  } = useForm<ReferralFormValues>({ defaultValues });
  const onSubmit = async (formData: ReferralFormValues) => {
    try {
      const { ResultMessage } = await newReferralRequest(formData);
      if (ResultMessage === "Customer Created") {
        reset();
        handleClose();
        toast.success("The new referral has been added successfully!", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        toast.error("Something went wrong!", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    } catch (error) {}
    return;
  };
  return (
    <div>
      <CTAbtn
        type="button"
        text="NEW REFERRAL"
        color="os-btn-primary"
        onClick={handleClickOpen}
      />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          New Referral
        </BootstrapDialogTitle>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogContent dividers>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mb-5">
              <div className="flex flex-col min-w-[200px]">
                <span>General Information</span>
                <label className="block mt-5">
                  <div className="flex">
                    <span className="text-black block text-sm font-light mb-2">
                      First Name
                    </span>
                    <span className="text-red-600">&nbsp;*</span>
                  </div>

                  <input
                    type="text"
                    placeholder="First Name"
                    className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
                                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
                                "
                    {...register("FirstName", {
                      required: {
                        value: true,
                        message: "First name is required.",
                      },
                    })}
                  />
                  <span className="text-os-primary block text-sm mt-2 -mb-4">
                    {errors?.FirstName?.message ?? ""}
                  </span>
                </label>
                <label className="block mt-5">
                  <div className="flex">
                    <span className="text-black block text-sm font-light mb-2">
                      Last Name
                    </span>
                    <span className="text-red-600">&nbsp;*</span>
                  </div>

                  <input
                    type="text"
                    placeholder="Last Name"
                    className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
                                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
                                "
                    {...register("LastName", {
                      required: {
                        value: true,
                        message: "First name is required.",
                      },
                    })}
                  />
                  <span className="text-os-primary block text-sm mt-2 -mb-4">
                    {errors?.LastName?.message ?? ""}
                  </span>
                </label>
                <label className="block mt-5">
                  <div className="flex">
                    <span className="text-black block text-sm font-light mb-2">
                      Phone Number
                    </span>
                    <span className="text-red-600">&nbsp;*</span>
                  </div>

                  <input
                    type="text"
                    placeholder="Phone Number"
                    className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
                                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
                                "
                    {...register("Phone", {
                      required: {
                        value: true,
                        message: "Phone number is required.",
                      },
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: "Please enter a valid phone number.",
                      },
                    })}
                    onChange={handlePhoneNumber}
                  />
                  <span className="text-os-primary block text-sm mt-2 -mb-4">
                    {errors?.Phone?.message ?? ""}
                  </span>
                </label>
                <label className="block mt-5">
                  <div className="flex">
                    <span className="text-black block text-sm font-light mb-2">
                      Email Address
                    </span>
                    <span className="text-red-600">&nbsp;*</span>
                  </div>

                  <input
                    type="text"
                    placeholder="Email Address"
                    className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
              "
                    {...register("Email", {
                      required: {
                        value: true,
                        message: "A valid email address is required.",
                      },
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address.",
                      },
                    })}
                  />
                  <span className="text-os-primary block text-sm mt-2 -mb-4">
                    {errors?.Email?.message ?? ""}
                  </span>
                </label>
                <label className="block mt-5">
                  <div className="flex">
                    <span className="text-black block text-sm font-light mb-2">
                      Closing Date
                    </span>
                    <span className="text-red-600">&nbsp;*</span>
                  </div>

                  <input
                    type="date"
                    placeholder="Closing Date"
                    className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
              "
                    {...register("ClosingDate", {
                      required: {
                        value: true,
                        message: "Closing date is required.",
                      },
                    })}
                  />
                  <span className="text-os-primary block text-sm mt-2 -mb-4">
                    {errors?.ClosingDate?.message ?? ""}
                  </span>
                </label>
              </div>
              <div className="flex flex-col min-w-[200px]">
                <span>New Home Information</span>
                <label className="block mt-5">
                  <div className="flex">
                    <span className="text-black block text-sm font-light mb-2">
                      Apartment Number
                    </span>
                    <span className="text-red-600">&nbsp;*</span>
                  </div>

                  <input
                    type="text"
                    placeholder="Apartment Number"
                    className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
              "
                    {...register("AptNumber", {
                      required: {
                        value: true,
                        message: "Apartment Number is required.",
                      },
                    })}
                  />
                  <span className="text-os-primary block text-sm mt-2 -mb-4">
                    {errors?.AptNumber?.message ?? ""}
                  </span>
                </label>
                <label className="block mt-5">
                  <div className="flex">
                    <span className="text-black block text-sm font-light mb-2">
                      Address
                    </span>
                    <span className="text-red-600">&nbsp;*</span>
                  </div>

                  <input
                    type="text"
                    placeholder="Address"
                    className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
              "
                    {...register("Address", {
                      required: {
                        value: true,
                        message: "Full address is required.",
                      },
                    })}
                  />
                  <span className="text-os-primary block text-sm mt-2 -mb-4">
                    {errors?.Address?.message ?? ""}
                  </span>
                </label>
                <label className="block mt-5">
                  <div className="flex">
                    <span className="text-black block text-sm font-light mb-2">
                      City
                    </span>
                    <span className="text-red-600">&nbsp;*</span>
                  </div>

                  <input
                    type="text"
                    placeholder="City"
                    className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
              "
                    {...register("City", {
                      required: {
                        value: true,
                        message: "City is required.",
                      },
                    })}
                  />
                  <span className="text-os-primary block text-sm mt-2 -mb-4">
                    {errors?.City?.message ?? ""}
                  </span>
                </label>
                <div className="grid grid-cols-[1fr_2fr] gap-4">
                  <label className="block mt-5">
                    <div className="flex">
                      <span className="text-black block text-sm font-light mb-2">
                        State
                      </span>
                      <span className="text-red-600">&nbsp;*</span>
                    </div>

                    <select
                      className="bg-white w-full border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block p-[9.5px]"
                      {...register("State", {
                        required: {
                          value: true,
                          message: "Required.",
                        },
                      })}
                      value={state}
                      onChange={(evt) => setState(evt.currentTarget.value)}
                    >
                      <option value="">State</option>
                      {STATE_DICTIONARY.map(({ value, label }) => (
                        <option value={value ?? ""} key={`${label}${value}`}>
                          {label}
                        </option>
                      ))}
                    </select>
                    <span className=" text-os-primary block text-sm mt-2 -mb-4">
                      {errors.State?.message ?? ""}
                    </span>
                  </label>

                  <label className="block mt-5">
                    <div className="flex">
                      <span className="text-black block text-sm font-light mb-2">
                        Zip
                      </span>
                      <span className="text-red-600">&nbsp;*</span>
                    </div>

                    <input
                      type="text"
                      placeholder="Zip"
                      className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
              "
                      {...register("Zip", {
                        required: {
                          value: true,
                          message: "Zip is required.",
                        },
                        pattern: {
                          value: /^(\d{5}(-\d{4})?)?$/,
                          message: "Enter valid zip.",
                        },
                      })}
                    />
                    <span className="text-os-primary block text-sm mt-2 -mb-4">
                      {errors?.Zip?.message ?? ""}
                    </span>
                  </label>
                </div>
                <label className="block mt-5">
                  <div className="flex">
                    <span className="text-black block text-sm font-light mb-2">
                      Notes
                    </span>
                  </div>

                  <textarea
                    placeholder="Notes"
                    rows={1}
                    className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
              "
                    {...register("Notes")}
                  />
                  <span className="text-os-primary block text-sm mt-2 -mb-4">
                    {errors?.Notes?.message ?? ""}
                  </span>
                </label>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex ">
              <CTAbtn
                type="button"
                text="CANCEL"
                color="os-btn-third"
                reversed={true}
                onClick={handleClose}
              />
              <div className="w-full ml-3">
                <CTAbtn
                  type="submit"
                  text={isSubmitting ? "SUBMITTING..." : "SUBMIT"}
                  color="os-btn-third"
                  disabled={isSubmitting}
                />
              </div>
            </div>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}
