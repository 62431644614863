import React, { useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useAuthContext } from "../../context/AuthContext";
import { useQuery } from "react-query";
import { getCommissionsRequest } from "../../api/dashboard/getCommissionsRequest";
import InlineLoader from "../shared/InlineLoader";
import downloadIcon from "../../assets/icons/download.png";
import MonthRangePickerModal from "../shared/MonthRangePickerModal";
import { format, startOfYear } from "date-fns";

interface MonthlyRevenueChartProps {
  height?: number;
  officeToken?: string | null;
  agentToken?: string | null;
}

const monthLabels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const MonthlyRevenueChart = (props: MonthlyRevenueChartProps) => {
  const firstDayOfYear = format(startOfYear(new Date()), "yyyy-MM-dd");
  const today = format(new Date(), "yyyy-MM-dd");
  const todayDate = new Date();
  const maxYear = todayDate.getUTCFullYear();

  const [authState] = useAuthContext();
  const { loggedInUser } = authState;
  const [beginDate, setBeginDate] = useState(firstDayOfYear);
  const [endDate, setEndDate] = useState(today);
  const { height = 285, officeToken, agentToken } = props;

  const query = useQuery(
    [
      "get-current-year-stats-for-chart",
      beginDate,
      endDate,
      officeToken,
      agentToken,
    ],
    () =>
      getCommissionsRequest(
        loggedInUser?.PortalToken,
        loggedInUser?.UserLevel,
        beginDate,
        endDate,
        officeToken,
        agentToken
      )
  );
  const { data, isLoading } = query;
  const revenueData = useMemo(() => {
    if (isLoading) {
      return [];
    }
    let arry: number[] = [];
    data?.Commissions.forEach((commission) => {
      if (commission?.Amount === null) commission.Amount = "0";
      arry.push(parseFloat(commission?.Amount));
    });
    return arry;
  }, [data, isLoading]);

  const categories = useMemo(() => {
    if (isLoading) {
      return [];
    }
    let arry: string[] = [];
    data?.Commissions.forEach((commission) => {
      let split = commission?.Date.split("/");
      let monthIndex = parseInt(split[0]) - 1;
      let month = monthLabels[monthIndex];
      let label = month + " " + split[1];
      arry.push(label);
    });
    return arry;
  }, [data, isLoading]);

  const handleMonthRangeApply = (begin, end) => {
    setBeginDate(begin);
    setEndDate(end);
  };

  // if (query.isLoading) {
  //   return <InlineLoader />;
  // }

  if (query.isError) {
    return (
      <span className="font-bold text-sm">
        We were not able to load this chart. Reload page and try again
      </span>
    );
  }

  const options: ApexOptions = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["#E346D4", "#36D6E7"],
    },
    colors: ["#E346D4", "#36D6E7"],
    yaxis: {
      labels: {
        formatter: function (value) {
          return "$ " + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
    },
    xaxis: {
      categories,
    },
    fill: {
      type: "solid",

      opacity: 0.5,
    },
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: false,
          customIcons: [
            {
              icon: `<img src=${downloadIcon} width="20">`,
              index: 5,
              title: "Download CSV File",
              class: "custom-icon",
              click: function () {
                window.open(`${data?.ExportUrl}`, "_blank");
              },
            },
          ],
        },
      },
    },
  };
  return (
    <div>
      <div className="flex justify-end mb-4">
        <MonthRangePickerModal
          minYear={2013}
          maxYear={maxYear}
          handleApply={handleMonthRangeApply}
        />
      </div>
      {isLoading && <InlineLoader />}
      {!isLoading && (
        <Chart
          options={options}
          height={height}
          type="area"
          series={[{ name: "Revenue", data: revenueData }]}
        />
      )}
    </div>
  );
};

export default MonthlyRevenueChart;
