import { Request } from "../Request";

export async function getAgentsListRequest(
  PortalToken: string | null | undefined,
  UserLevel: string | null | undefined,
  ReturnRows?: number | null,
  PageNumber?: number | null,
  SearchType?: string | null,
  SearchValue?: string | null,
  SortValue?: string | null,
  SortDirection?: string | null
) {
  const data = {
    PortalToken,
    UserLevel,
    ReturnRows,
    PageNumber,
    SortValue,
    SortDirection,
  };
  if (SearchType && SearchValue && SearchValue.length >= 4) {
    data["SearchType"] = SearchType;
    data["SearchValue"] = SearchValue;
  }
  const response = await Request("get-agent-list", data);
  return response.data;
}
