import React, { ReactElement, useMemo } from "react";
import { useQuery } from "react-query";
import { getCommissionsRequest } from "../../api/dashboard/getCommissionsRequest";
import { LoggedInUser } from "../../interfaces/LoggedInUser";
import DataBlock from "./DataBlock";
// import { formatDate } from "../../helpers/dateTime";
import { floatToFormattedPrice } from "../../helpers/money";

interface RevenueStatsProps {
  loggedInUser: LoggedInUser | null;
  officeToken: string | null | undefined;
  agentToken: string | null | undefined;
}

export default function RevenueStats(props: RevenueStatsProps): ReactElement {
  const { loggedInUser, officeToken, agentToken } = props;
  const query = useQuery(["get-revenue-stats", officeToken, agentToken], () =>
    getCommissionsRequest(
      loggedInUser?.PortalToken,
      loggedInUser?.UserLevel,
      "",
      "",
      officeToken,
      agentToken
    )
  );

  const { data, isLoading } = query;
  const monthlyRevenue = useMemo(() => {
    if (isLoading) {
      return 0;
    }
    const currntMonth = data?.Commissions[data?.Commissions.length - 1];

    return currntMonth?.Amount;
  }, [data, isLoading]);

  const ytdRevenue = useMemo(() => {
    if (isLoading) {
      return 0;
    }
    let i = 0;
    data?.Commissions.forEach((commission) => {
      if (typeof commission?.Amount === "string") {
        i += parseFloat(commission?.Amount);
      }
    });
    return i;
  }, [data, isLoading]);

  return (
    <div className="flex flex-col w-full mb-10">
      <span className="text-xl font-light mb-6 tracking-wider">REVENUE</span>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 ">
        <DataBlock
          title="YTD Revenue"
          num={floatToFormattedPrice(ytdRevenue)}
          isLoading={isLoading}
        />
        <DataBlock
          title="Monthly Revenue"
          num={floatToFormattedPrice(
            parseInt(typeof monthlyRevenue === "string" ? monthlyRevenue : "0")
          )}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}
