import React, {createContext, useReducer} from 'react';

function filterReducer(state, action) {
  const newState = {...state};
  const k = action.key;
  switch(action.type) {
    case 'CREATE_FILTER':
      newState[k] = defaultFilters[k];
      return {...newState};
    case 'UPDATE_VALUE':
      newState[k].value = action.payload;
      return {...newState};
    case 'RESET_FILTERS':
      newState[k] = defaultFilters[k];
      return {...newState};
    default:
      return state;
  }
}

const defaultFilters = {
  'agents': {
    search: {value: '', column: 'Search', type: 'search'},
    searchType: {value: '', type: 'searchType', options:[{value: 'Name', label: 'Name'}, {value: 'Email', label: 'Email'}]},
    lostReferrals: {value: false, type: 'switch'},
    agent: {value: '', type: 'apiSelect', action: 'get-agent-list'},
    services: {value: '', type: 'simpleSelect', options: [{value: '', label: 'All'}, {value: 'security', label: 'Security'}]},
    dateAddedRange: {column: 'DateAdded', value: '', type: 'dateRange'},
    dateAddedDate: {column: 'DateAdded', value: '', type: 'date'},
  },
  'offices': {

  },
  'leads': {

  }
};

export const FilterContext = createContext({});

export const FilterProvider = (props) => {
  const [state, dispatch] = useReducer(filterReducer, {});
  return(
    <FilterContext.Provider value={[state, dispatch]}>
      {props.children}
    </FilterContext.Provider>
  );
}

export const useFilterContext = () => React.useContext(FilterContext);