import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Office } from "../../interfaces/Office";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { slashDate } from "../../helpers/dateTime";
import { singleAddress } from "../../helpers/string";

type Anchor = "right";

interface OfficeDetailsDrawerProps {
  office: Office | undefined;
  open: boolean;
  setOpen: (val: boolean) => void;
}

export default function OfficeDetailsDrawer(
  props: OfficeDetailsDrawerProps
): React.ReactElement | any {
  const { office, open, setOpen } = props;
  const [, setState] = React.useState({
    right: open,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(open);
    };

  useEffect(() => {
    setState((state) => {
      return { ...state, right: open };
    });
  }, [open]);

  return (
    <div>
      {office && (
        <React.Fragment key={"right"}>
          <Drawer
            anchor={"right"}
            open={open}
            onClose={toggleDrawer("right", false)}
          >
            <Box
              sx={{ width: 400 }}
              role="presentation"
              onClick={toggleDrawer("right", false)}
              onKeyDown={toggleDrawer("right", false)}
            >
              <i className="absolute top-5 right-5 cursor-pointer fas fa-times"></i>
            </Box>
            <section className="flex flex-col w-full justify-center items-center">
              <div className="block mt-20 mb-5">
                <span className="text-2xl">{office.OfficeName}</span>
              </div>

              <span className="font-light text-sm">
                Address: {singleAddress(office)}
              </span>
              <div className="flex p-10">
                <Link to={`/`} state={{ officeToken: office.OfficeToken }}>
                  <Tooltip title="View As">
                    <i className="fas fa-eye"></i>
                  </Tooltip>
                </Link>
                <a href={`tel:${office.Phone}`} className="ml-5">
                  <Tooltip title="Call">
                    <i className="fas fa-phone"></i>
                  </Tooltip>
                </a>
                <a href={`mailto:${office.Email}`} className="ml-5">
                  <Tooltip title="Email">
                    <i className="fas fa-envelope"></i>
                  </Tooltip>
                </a>
              </div>
              <span className="font-light text-sm mb-3">Member Since</span>
              <span className="text-sm font-bold mb-10">
                {office.DateAdded !== null && office.DateAdded !== ""
                  ? slashDate(office.DateAdded)
                  : "---"}
              </span>
              <span className="font-light text-sm mb-3">Leads Submitted</span>
              <span className="text-sm font-bold mb-10">
                {office.LeadsSubmitted}
              </span>
              <span className="font-light text-sm mb-3">Leads Sold</span>
              <span className="text-sm font-bold mb-10">
                {office.LeadsSold}
              </span>
            </section>
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
}
