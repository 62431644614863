import React from "react";
import Nav from "../components/dashboard/Nav";
import Header from "../components/dashboard/Header";
import { useAuthContext } from "../context/AuthContext";
import { ChangePasswordForm } from "../components/myaccount/ChangePasswordForm";
import altProfileImg from "../assets/alts/profile-alt.png";
import altCompanyImg from "../assets/alts/company-alt.png";
import { PageMeta } from "../components/shared/PageMeta";

export default function ChangePassword() {
  const [authState] = useAuthContext();
  const { loggedInUser } = authState;

  return (
    <>
      <PageMeta title="Change Password" />
      <Nav loggedInUser={loggedInUser} />
      {/* body of the page */}
      <main className="w-full">
        {/* -------- */}
        <Header title="Change Password" loggedInUser={loggedInUser} />
        {/* -------- */}
        <section className="">
          <div className="relative w-full h-full overflow-y-auto">
            <div className="flex flex-col items-center w-2/3 lg:w-1/3 md:w-1/2 mx-auto">
              <img
                className="mt-14 w-40 h-40 rounded-full object-cover mb-20"
                src={
                  loggedInUser?.UserLevel === "Agent"
                    ? loggedInUser?.Photo !== ""
                      ? loggedInUser?.Photo
                      : altProfileImg
                    : loggedInUser?.TerritoryRepPhoto !== ""
                    ? loggedInUser?.TerritoryRepPhoto
                    : altCompanyImg
                }
                alt="OneSource Solutions"
              />
              <div className="block w-full mb-10 xl:px-20 lg:px-15 md:px-10">
                <ChangePasswordForm />
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
