import React, { useMemo } from "react";
import { useQuery } from "react-query";
import ProfileBlock from "../../components/dashboard/ProfileBlock";
import MonthlyRevenueChart from "../../components/charts/MonthlyRevenue";
import { getTopAgentsRequest } from "../../api/dashboard/getTopAgentsRequest";
import { getTopOfficesRequest } from "../../api/dashboard/getTopOfficesRequest";
import RevenueStats from "./RevenueStats";
import { LoggedInUser } from "../../interfaces/LoggedInUser";
import Tooltip from "@mui/material/Tooltip";
import InlineLoader from "../shared/InlineLoader";
import { Link } from "react-router-dom";

interface RevenueSectionProps {
  loggedInUser: LoggedInUser | null;
  setAgentDetailsOpen: (value: boolean) => void;
  setAgentDetails: (value) => void;
  setOfficeDetailsOpen: (value: boolean) => void;
  setOfficeDetails: (value) => void;
  OfficeToken?: string | null | undefined;
  AgentToken?: string | null | undefined;
}

export default function RevenueSection(props: RevenueSectionProps) {
  const {
    loggedInUser,
    setAgentDetailsOpen,
    setAgentDetails,
    setOfficeDetailsOpen,
    setOfficeDetails,
    OfficeToken,
    AgentToken,
  } = props;
  const agentsQuery = useQuery(["get-top-agents-request", OfficeToken], () =>
    getTopAgentsRequest(
      loggedInUser?.PortalToken,
      loggedInUser?.UserLevel,
      OfficeToken
    )
  );
  const { data: agentData, isLoading } = agentsQuery;

  const agents = useMemo(() => {
    const agents = agentData?.Agents ?? {};
    const agentsAry = Object.values(agents);
    return agentsAry;
  }, [agentData]);

  const officesQuery = useQuery(["get-top-offices-request"], () =>
    getTopOfficesRequest(loggedInUser?.PortalToken, loggedInUser?.UserLevel)
  );
  const { data: officeData, isLoading: officesIsLoading } = officesQuery;
  const offices = useMemo(() => {
    const offices = officeData?.Offices ?? {};
    const officesAry = Object.values(offices);
    return officesAry;
  }, [officeData]);

  const openAgentDetailsDrawer = (agent) => {
    setAgentDetails(agent);
    setAgentDetailsOpen(true);
  };

  const openOfficeDetailsDrawer = (office) => {
    setOfficeDetails(office);
    setOfficeDetailsOpen(true);
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-[6fr_2fr] gap-5">
      <div className="flex flex-col w-full mb-10">
        <RevenueStats
          loggedInUser={loggedInUser}
          officeToken={OfficeToken}
          agentToken={AgentToken}
        />
        <div className="flex flex-col w-full mb-10">
          <span className="text-xl font-light tracking-wider">
            MONTHLY YTD REVENUE
          </span>
          <div className="w-full">
            <MonthlyRevenueChart
              height={340}
              officeToken={OfficeToken}
              agentToken={AgentToken}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row lg:flex-col w-full">
        {!AgentToken && (
          <div className="flex flex-col w-full mb-10 mr-0 sm:mr-10 lg:mr-0">
            <span className="text-xl font-light mb-6 tracking-wider">
              TOP PERFORMING AGENTS
            </span>
            {agents &&
              !isLoading &&
              agents.slice(0, 3).map((agent: any, index) => (
                <div
                  key={index}
                  className="flex w-full justify-between items-center mb-8"
                >
                  <div className="cursor-pointer">
                    <ProfileBlock
                      name={`${agent.FirstName} ${agent.LastName}`}
                      title={`Office: ${
                        agent.Company.substring(0, 15) +
                        (agent.Company.length > 15 ? "..." : "")
                      }`}
                      url={agent.Photo}
                      onClick={() => {
                        openAgentDetailsDrawer(agent);
                      }}
                    />
                  </div>

                  <Link to={`/`} state={{ agentToken: agent.AgentToken }}>
                    <Tooltip title="View As">
                      <i className="fas fa-eye cursor-pointer"></i>
                    </Tooltip>
                  </Link>
                </div>
              ))}
            {agents.length === 0 && <span>Nothing to show</span>}
            {isLoading && <InlineLoader />}
          </div>
        )}
        {!AgentToken &&
          !OfficeToken &&
          loggedInUser?.UserLevel === "Corporate" && (
            <div className="flex flex-col w-full">
              <span className="text-xl font-light mb-6 tracking-wider">
                TOP PERFORMING OFFICES
              </span>
              {offices &&
                !officesIsLoading &&
                offices.slice(0, 3).map((office: any, index) => (
                  <div
                    key={index}
                    className="flex w-full justify-between items-center mb-8"
                  >
                    <div className="cursor-pointer">
                      <ProfileBlock
                        name={office.Company}
                        title={`Office: ${
                          office.Company.substring(0, 15) +
                          (office.Company.length > 15 ? "..." : "")
                        }`}
                        url={office.Photo}
                        onClick={() => {
                          openOfficeDetailsDrawer(office);
                        }}
                      />
                    </div>
                    <Link to={`/`} state={{ officeToken: office.OfficeToken }}>
                      <Tooltip title="View As">
                        <i className="fas fa-eye cursor-pointer"></i>
                      </Tooltip>
                    </Link>
                  </div>
                ))}
              {offices.length === 0 && <span>Nothing to show</span>}
              {officesIsLoading && <InlineLoader />}
            </div>
          )}
      </div>
    </div>
  );
}
