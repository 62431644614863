import React, { useState } from "react";
// import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { format, parse, isValid } from "date-fns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { CTAbtn } from "../form/Buttons";
import { filterParamsI } from "../../pages/ClientsLeads";
import { inputClass, labelClass } from "../form/Input";
import Switch from "react-switch";
import Multiselect from "multiselect-react-dropdown";

type Anchor = "right";

interface TableFiltersDrawerProps {
  applyFilters: (filters: filterParamsI) => void;
  btnType: string;
  defaultFilterValues: {[key: string]: null | string}
}

export default function TableFiltersDrawer(
  props: TableFiltersDrawerProps
): React.ReactElement | any {
  const [state, setState] = React.useState({
    right: false,
  });
  const { applyFilters, btnType, defaultFilterValues } = props;
  const {ShowLost, Status, Services, DateField, StartDate, EndDate, SoldOnly} = defaultFilterValues;
  const ShowLostBool = ShowLost === "1";
  const SoldOnlyBool = SoldOnly === "1";

  const initialStartDate = StartDate
    ? parse(StartDate, "yyyy-MM-dd", new Date())
    : null;
  const initialEndDate = EndDate
    ? parse(EndDate, "yyyy-MM-dd", new Date())
    : null;
  const [StartDateDate, setStartDateDate] = useState<Date | null>(
    initialStartDate
  );
  const [EndDateDate, setEndDateDate] = useState<Date | null>(initialEndDate);
  const initServices = Services ? Services.split(",") : null;
  interface defaultValuesI {
    ShowLost: boolean;
    Status: string | null;
    Services: string[] | null;
    DateField: string | null;
    StartDate: string | null;
    EndDate: string | null;
    SoldOnly: boolean;
  }
  const defaultValues = {
    ShowLost: ShowLostBool,
    Status,
    Services: initServices,
    DateField,
    StartDate,
    EndDate,
    SoldOnly: SoldOnlyBool
  } as defaultValuesI;
  const { register, handleSubmit, getValues, watch, setValue } = useForm({
    defaultValues,
  });
  const showLost = watch("ShowLost");
  const soldOnly = watch("SoldOnly");
  const DateFieldValue = watch("DateField");
  const selectedServices = watch("Services");

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const selectServices = (services) => {
    setValue("Services", services);
  };

  const applyClose = (formData) => {
    applyFilters(formData);
    setState({ ...state, right: false });
  };

  const resetAllFormValues = () => {
    const formValues = getValues();
    for (const [field] of Object.entries(formValues)) {
      if (field === "ShowLost") {
        setValue("ShowLost", false);
      } else if (field === "StartDate" || field === "EndDate") {
        setEndDateDate(null);
        setStartDateDate(null);
        setValue(field, "");
      } else {
        // @ts-ignore
        setValue(field, "");
      }
    }
  };

  return (
    <div>
      <React.Fragment key={"right"}>
        {btnType === "icon" && (
          <button onClick={toggleDrawer("right", true)}>
            <i className="fas fa-filter pl-5"></i>
          </button>
        )}
        {btnType === "CTA" && (
          <div onClick={toggleDrawer("right", true)}>
            <CTAbtn
              type="button"
              text="FILTERS"
              color="os-btn-third"
              reversed={true}
            />
          </div>
        )}

        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          <Box
            sx={{ width: 350 }}
            role="presentation"
            onClick={toggleDrawer("right", false)}
            onKeyDown={toggleDrawer("right", false)}
          >
            <i className="absolute top-5 right-5 cursor-pointer fas fa-times"></i>
          </Box>
          <form onSubmit={handleSubmit(applyClose)}>
            <div className="flex flex-col w-full p-5">
              <h1 className="text-2xl mb-5">Table Filters</h1>
              <div className="flex w-full justify-between items-center pb-3">
                <span className="text-sm font-light">LOST REFERRALS</span>
                <Switch
                  aria-label="Lost Referrals"
                  checked={showLost}
                  onChange={(checked) => setValue("ShowLost", checked)}
                  height={12}
                  width={24}
                  handleDiameter={10}
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
              <div className="flex w-full justify-between items-center border-b border-gray pb-3">
                <span className="text-sm font-light">Sold Only</span>
                <Switch
                  aria-label="SoldOnly"
                  checked={soldOnly}
                  onChange={(checked) => setValue("SoldOnly", checked)}
                  height={12}
                  width={24}
                  handleDiameter={10}
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>

              <label className={labelClass}>Status</label>
              <select {...register("Status")} className={inputClass}>
                <option value="">Choose Status</option>
                <option value="New">New</option>
                <option value="Research">Research</option>
                <option value="Scheduled">Scheduled</option>
                <option value="Processing">Processing</option>
                <option value="Completed">Completed</option>
                <option value="Lost">Lost</option>
              </select>

              <label className={labelClass}>Services</label>
              <Multiselect
                isObject={false}
                options={[
                  "Internet",
                  "Cable",
                  "TV/Satellite",
                  "Telephone",
                  "Security/Alarm Systems",
                  "Electric",
                  "Gas",
                  "Water",
                  "Sewer",
                  "Trash",
                ]}
                onSelect={selectServices}
                onRemove={selectServices}
                selectedValues={selectedServices}
                showArrow={false}
                closeOnSelect={true}
                style={{
                  searchBox: {
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: 310,
                  },
                }}
              />

              <label className={labelClass}>Date Filter</label>
              <select {...register("DateField")} className={inputClass}>
                <option value="">Choose Filter</option>
                <option value="DateAdded">DateAdded</option>
                <option value="ClosingDate">ClosingDate</option>
                <option value="Completed">Completed</option>
                <option value="OrderDate">OrderDate</option>
                <option value="InstallationDate">InstallationDate</option>
              </select>

              {DateFieldValue !== "" && DateFieldValue !== null && (
                <>
                  <span className="text-sm font-light mt-7">DATE RANGE</span>
                  <div className="grid grid-cols-1 gap-5 mt-3 w-full">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        minDate={parse("2013-01-01", "yyyy-MM-dd", new Date())}
                        maxDate={new Date()}
                        label="Beginning Date"
                        value={StartDateDate}
                        onChange={(newValue) => {
                          if (newValue !== null && isValid(newValue)) {
                            setValue(
                              "StartDate",
                              format(newValue, "yyyy-MM-dd")
                            );
                          } else {
                            setValue("StartDate", null);
                          }
                          setStartDateDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        minDate={parse("2013-01-01", "yyyy-MM-dd", new Date())}
                        maxDate={new Date()}
                        label="End Date"
                        value={EndDateDate}
                        onChange={(newValue) => {
                          if (newValue !== null && isValid(newValue)) {
                            setValue("EndDate", format(newValue, "yyyy-MM-dd"));
                          } else {
                            setValue("EndDate", null);
                          }
                          setEndDateDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </>
              )}

              <div className="flex flex-col w-full mt-10">
                <CTAbtn
                  type="button"
                  text="RESET FILTERS"
                  color="os-btn-third"
                  reversed={true}
                  onClick={resetAllFormValues}
                />
                <div className="my-3">
                  <CTAbtn
                    type="submit"
                    text="APPLY AND CLOSE"
                    color="os-btn-third"
                  />
                </div>
              </div>
            </div>
          </form>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
