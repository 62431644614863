import React, { ReactElement } from "react";
import logo from "../../assets/logos/onesource-fullcolor.svg";

export default function SectionDivider(): ReactElement {
  return (
    <div className="grid grid-cols-[1fr_1fr_1fr] sm:grid-cols-[3fr_1fr_3fr] mb-10 ">
      <hr className="mt-5 border-1 border-gray-400" />
      <div className="flex items-center justify-center w-full">
        <img src={logo} alt="OneSource solutions" className="w-2/3 px-2" />
      </div>

      <hr className="mt-5 border-1 border-gray-400" />
    </div>
  );
}
