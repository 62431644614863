import React, { useState } from "react";
import Nav from "../components/dashboard/Nav";
import Header from "../components/dashboard/Header";
import { useAuthContext } from "../context/AuthContext";
import clsx from "clsx";
import { useQuery } from "react-query";
import { getDocumentsListRequest } from "../api/mydocuments/getDocumentsListRequest";
import { PageMeta } from "../components/shared/PageMeta";

export default function MyDocuments() {
  const [authState] = useAuthContext();
  const { loggedInUser } = authState;

  const [gridView, setGridView] = useState(true);
  const isGrid = gridView;

  const query = useQuery(["get-documents-list"], () =>
    getDocumentsListRequest(loggedInUser?.PortalToken, loggedInUser?.UserLevel)
  );
  const { data } = query;

  const documents = data?.Documents;
  return (
    <>
      <PageMeta title="My Documents" />
      <Nav loggedInUser={loggedInUser} />
      {/* body of the page */}
      <main className="w-full">
        {/* -------- */}
        <Header title="My Documents" loggedInUser={loggedInUser} />
        {/* -------- */}
        <section className="">
          <div className="grid grid-cols-1 md:grid-cols-[1fr_1.5fr]  lg:grid-cols-[1fr_1fr_1fr] px-10 mt-10 items-center">
            <div className="hidden lg:block">
              <span>Page Layout:</span>
              <button onClick={() => setGridView(true)}>
                <i
                  className={`fa-${isGrid ? "solid" : "regular"} fa-grid ml-4`}
                ></i>
              </button>
              <button onClick={() => setGridView(false)}>
                <i
                  className={`fa-${!isGrid ? "solid" : "light"} fa-list ml-4`}
                ></i>
              </button>
            </div>
          </div>
          <div
            className={clsx(
              `grid grid-cols-1 md:grid-cols-2 px-5 md:px-10 lg:px-40`,
              {
                "lg:grid-cols-3": isGrid,
                "lg:grid-cols-2": !isGrid,
              }
            )}
          >
            {isGrid &&
              documents &&
              documents.map((document: any, index) => (
                <a
                  key={index}
                  href={document.URL}
                  target="_blank"
                  rel="noreferrer"
                  className="flex flex-col justify-center w-full p-5 items-center cursor-pointer"
                >
                  {document.FileName.split(".").pop() === "pdf" ? (
                    <i className="fa-light fa-file-pdf text-4xl mb-3"></i>
                  ) : document.FileName.split(".").pop() === "png" ? (
                    <img src={document.URL} alt="" className="w-10 h-10 mb-3" />
                  ) : document.FileName.split(".").pop() === "jpg" ? (
                    <img src={document.URL} alt="" className="w-10 h-10 mb-3" />
                  ) : (
                    <i className="fa-light fa-file-lines text-4xl mb-3"></i>
                  )}

                  <span>{document.FileName}</span>
                  <span className="text-sm font-light">
                    Created: {document.FileCreated}
                  </span>
                </a>
              ))}
            {!isGrid &&
              documents &&
              documents.map((document: any, index) => (
                <a
                  key={index}
                  href={document.URL}
                  target="_blank"
                  rel="noreferrer"
                  className="flex justify-left w-full p-5 items-center cursor-pointer"
                >
                  {document.FileName.split(".").pop() === "pdf" ? (
                    <i className="fa-light fa-file-pdf text-4xl"></i>
                  ) : document.FileName.split(".").pop() === "png" ? (
                    <img src={document.URL} alt="" className="w-10 h-10" />
                  ) : document.FileName.split(".").pop() === "jpg" ? (
                    <img src={document.URL} alt="" className="w-10 h-10" />
                  ) : (
                    <i className="fa-light fa-file-lines text-4xl mb-3"></i>
                  )}
                  <div className="flex flex-col text-left ml-4">
                    <span>{document.FileName}</span>
                    <span className="text-sm font-light">
                      Created 00/00/0000 @ 00:00 PM CST
                    </span>
                  </div>
                </a>
              ))}
          </div>
        </section>
      </main>
    </>
  );
}
