import React, {ReactElement, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

interface HasPermProps {
  perms: string[];
  userLevel: string | undefined;
  redirectTo: string;
  children: ReactElement | null;
}
export default function HasPerm(props: HasPermProps) {

  const navigate = useNavigate();

  const {perms, redirectTo, userLevel} = props;

  useEffect(() => {
    if(!userLevel || !perms.includes(userLevel)) {
      navigate(redirectTo)
    }
  }, [perms, redirectTo, userLevel, navigate])

  return <>{props.children}</>;
}