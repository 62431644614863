import React from "react";
import { logInRequest } from "../../api/auth/loginRequest";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { InputBlock, CheckboxBlock } from "../form/Input";
import { CTAbtn } from "../form/Buttons";
import { useAuthContext } from "../../context/AuthContext";

interface LoginFormValues {
  Email: string;
  Password: string;
  remember: boolean;
}

const defaultValues: Partial<LoginFormValues> = {
  Email: "",
  Password: "",
  remember: false,
};

export const LoginForm = () => {
  const {
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
    register,
  } = useForm<LoginFormValues>({ defaultValues });
  const [, authActions] = useAuthContext();

  const onSubmit = async (formData: LoginFormValues) => {
    try {
      const { ResultMessage, loggedInUser } = await logInRequest(formData);
      if (ResultMessage === "Successful Login") {
        authActions.signIn(loggedInUser, formData.remember);
      } else {
        setError(
          "Email",
          {
            type: "custom",
            message: ResultMessage,
          },
          { shouldFocus: true }
        );
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 422) {
        setError(
          "Email",
          {
            type: "custom",
            message: "Wrong email and password combination",
          },
          { shouldFocus: true }
        );
      } else {
        // reportError("Error logging in", error);
        // showErrorToast("We were not able to log you in. Try again");
      }
    }
    return;
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <InputBlock
          name="Email"
          label="EMAIL ADDRESS"
          placeholder="Your Email Address"
          type="text"
          register={register}
          error={errors.Email?.message}
          labelClass="text-white"
          errorClass="text-os-primary"
        />
        <div className="mt-6">
          <InputBlock
            name="Password"
            label="PASSWORD"
            placeholder="Password"
            type="password"
            register={register}
            error={errors.Password?.message}
            labelClass="text-white"
            errorClass="text-os-primary"
          />
        </div>

        <div className="flex justify-between items-center mt-8">
          <CheckboxBlock
            label="REMEMBER ME"
            name="remember"
            register={register}
            error={""}
            type="checkbox"
          />
          <Link
            to="/forgotpassword"
            className="text-white font-light text-sm text-right hover:underline"
          >
            Forgot Password
          </Link>
        </div>
        <div className="mt-4">
          <CTAbtn
            type="submit"
            text={isSubmitting ? "Logging in..." : "Log In"}
            disabled={isSubmitting}
            color="os-btn-primary"
          />
        </div>
      </form>
    </>
  );
};
