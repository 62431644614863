import { Request } from "../Request";

type FormData = {
  PortalToken: string;
  UserLevel: string;
  Password: string;
};

export async function forcePasswordResetRequest(formData: FormData) {
  const { PortalToken, UserLevel, Password } = formData;
  const response = await Request("user-set-password", {
    PortalToken,
    UserLevel,
    Password,
  });
  return response.data;
}
