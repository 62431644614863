export const serviceSummaryFromCustomer = (customer) => {
  let utilties = 0;
  let telcom = 0;
  let security = 0;
  customer.Utilities.forEach((u) => {
    utilties++;
  });
  customer.PaidServices.forEach((ps) => {
    if (ps.Service === "Security/Alarm Systems") {
      security++;
    } else {
      telcom++;
    }
  });
  return `${telcom} Telcom / ${utilties} Utility / ${security} Security`;
};
