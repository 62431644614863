export function singleAddress(obj): string {
  let address = addressFirstLine(obj);
  address += addressSecondLine(obj, true);
  return address;
}

export function addressFirstLine(obj): string {
  let address = "";
  if (obj.Address) {
    address += obj.Address;
  }
  return address;
}

export function addressSecondLine(obj, prependComma = false): string {
  let address = "";
  if (obj.City) {
    if (obj.Address && prependComma) {
      address += ", ";
    }
    address += obj.City;
  }
  if (obj.State) {
    if (obj.City) {
      address += ", ";
    }
    address += obj.State;
  }
  if (obj.Zip) {
    address += ` ${obj.Zip}`;
  }
  return address;
}
