import { Request } from "../Request";

type FormData = {
  PortalToken: string | null | undefined;
  UserLevel: string | null | undefined;
  FirstName: string;
  LastName: string;
  Phone: string;
  Email: string;
  AptNumber: string;
  Address: string;
  City: string;
  State: string;
  Zip: string;
  ClosingDate: string;
  Notes?: string;
};

export async function newReferralRequest(formData: FormData) {
  const {
    PortalToken,
    UserLevel,
    FirstName,
    LastName,
    Phone,
    Email,
    AptNumber,
    Address,
    City,
    State,
    Zip,
    ClosingDate,
    Notes,
  } = formData;
  const response = await Request("agent-lead-create", {
    PortalToken,
    UserLevel,
    FirstName,
    LastName,
    Phone,
    Email,
    AptNumber,
    Address,
    City,
    State,
    Zip,
    ClosingDate,
    Notes,
  });
  return response.data;
}
