import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { CTAbtn } from "../form/Buttons";
import { useAuthContext } from "../../context/AuthContext";
import { agentAccountUpdateRequest } from "../../api/myaccount/agentAccountUpdateRequest";

interface AccountFormValues {
  PortalToken: string | null | undefined;
  UserLevel: string | undefined;
  FirstName: string | undefined;
  LastName: string | undefined;
  Email: string | undefined;
  Password: string | undefined;
}

export const AgentAccountForm = () => {
  const [authState, authHandlers] = useAuthContext();
  const { loggedInUser } = authState;

  const defaultValues: Partial<AccountFormValues> = {
    PortalToken: loggedInUser?.PortalToken,
    UserLevel: loggedInUser?.UserLevel,
    FirstName: loggedInUser?.FirstName,
    LastName: loggedInUser?.LastName,
    Email: loggedInUser?.Email,
    Password: "thisisafakepassword",
  };

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    register,
  } = useForm<AccountFormValues>({ defaultValues });

  const onSubmit = async (formData: AccountFormValues) => {
    try {
      const { ResultMessage } = await agentAccountUpdateRequest(formData);
      if (ResultMessage === "Request Successful" && loggedInUser) {
        const newUserValues = {
          ...loggedInUser,
          FirstName: formData.FirstName,
          LastName: formData.LastName,
        };
        authHandlers.updateLoggedInUser(newUserValues);
      }
    } catch (error) {}
    return;
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <label className="block mt-5">
          <div className="flex">
            <span className="text-black block text-sm font-light mb-2">
              First Name
            </span>
            <span className="text-red-600">&nbsp;*</span>
          </div>

          <input
            type="text"
            placeholder="First Name"
            className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
              "
            {...register("FirstName", {
              minLength: {
                value: 4,
                message: "Please enter at least 4 characters",
              },
              required: { value: true, message: "First name is required." },
            })}
          />
          <span className="text-os-primary block text-sm mt-2 -mb-4">
            {errors?.FirstName?.message ?? ""}
          </span>
        </label>
        <div className="mt-10">
          <label className="block mt-5">
            <div className="flex">
              <span className="text-black block text-sm font-light mb-2">
                Last Name
              </span>
              <span className="text-red-600">&nbsp;*</span>
            </div>

            <input
              type="text"
              placeholder="Last Name"
              className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
              "
              {...register("LastName", {
                minLength: {
                  value: 4,
                  message: "Please enter at least 4 characters",
                },
                required: { value: true, message: "Last name is required." },
              })}
            />
            <span className="text-os-primary block text-sm mt-2 -mb-4">
              {errors?.LastName?.message ?? ""}
            </span>
          </label>
        </div>
        <div className="mt-10">
          <label className="block mt-5">
            <div className="flex">
              <span className="text-black block text-sm font-light mb-2">
                Email Address
              </span>
            </div>

            <input
              type="text"
              placeholder="Email Address"
              className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
              "
              {...register("Email", {
                required: {
                  value: true,
                  message: "A valid email address is required.",
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address.",
                },
                disabled: true,
              })}
            />
            <span className="text-os-primary block text-sm mt-2 -mb-4">
              {errors?.Email?.message ?? ""}
            </span>
          </label>
        </div>
        <div className="flex flex-col mt-10">
          <div className="flex justify-between w-full">
            <span className="text-sm font-light">Password</span>
            <Link
              to="/changepassword"
              className="text-os-third font-light text-sm text-right hover:underline mr-3"
            >
              Change Password
            </Link>
          </div>
          <label className="block mt-5">
            <input
              type="password"
              placeholder=""
              className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-sm text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200
              "
              {...register("Password", {
                disabled: true,
              })}
            />
          </label>
        </div>

        <div className="mt-8">
          <CTAbtn
            type="submit"
            text={isSubmitting ? "SAVING..." : "SAVE CHANGES"}
            disabled={isSubmitting}
            color="os-btn-third"
          />
        </div>
      </form>
    </>
  );
};
