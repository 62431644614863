import { Request } from "../Request";

type Credentials = {
      Email: string;
      Password: string;
    }

export async function logInRequest(credentials: Credentials) {
  const {Email, Password} = credentials

  const response = await Request("user-login", {Email, Password});

  const {ResultCode, ResultMessage} = response.data;
  return {loggedInUser: response.data, ResultCode, ResultMessage};
}
