import React, { useState, useEffect, useCallback } from "react";
import { CTAbtn } from "../components/form/Buttons";
import Nav from "../components/dashboard/Nav";
import Header from "../components/dashboard/Header";
import { useAuthContext } from "../context/AuthContext";
import { useQuery } from "react-query";
import Pagination from "../components/shared/Pagination";
import ClientBlock from "../components/dashboard/ClientBlock";
import SearchCtaBtnsForMobile from "../components/dashboard/SearchCtaBtnsForMobile";
import { getClientsListRequest } from "../api/clients/getClientsListRequest";
import InlineLoader from "../components/shared/InlineLoader";
import TableFiltersDrawer from "../components/modals/TableFilters";
import ClientsSearch from "../components/modals/ClientsSearch";
import ClientDetailsDrawer from "../components/modals/ClientDetails";
import { PageMeta } from "../components/shared/PageMeta";
import clsx from "clsx";
import { useSearchParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

export interface filterParamsI {
  [filter: string]: string | boolean | null;
}
export default function ClientsLeads() {
  const [authState] = useAuthContext();
  const { loggedInUser } = authState;
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [total, setTotal] = useState(0);
  const [searchType, setSearchType] = useState("Name");
  const [searchValue, setSearchValue] = useState("");
  const [clientDetails, setClientDetails] = useState();
  const [sortValue, setSortValue] = useState("DateAdded");
  const [sortDir, setSortDir] = useState("Down");
  const [detailsOpen, setDetailsOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const StartDate = searchParams.get("StartDate");
  const EndDate = searchParams.get("EndDate");
  const DateField = searchParams.get("DateField");
  const Status = searchParams.get("Status");
  const ShowLost = searchParams.get("ShowLost");
  const SoldOnly = searchParams.get("SoldOnly");
  const Services = searchParams.get("Services");
  const ServicesAry = Services ? Services.split(",") : null;
  const defaultFilterValues = {
    StartDate,
    EndDate,
    DateField,
    Status,
    ShowLost,
    SoldOnly,
    Services,
  };

  const query = useQuery(
    [
      "get-clients-list",
      page,
      perPage,
      searchType,
      searchValue,
      sortValue,
      sortDir,
      StartDate,
      EndDate,
      DateField,
      Status,
      SoldOnly,
      ShowLost,
      Services,
    ],
    () =>
      getClientsListRequest(
        loggedInUser?.PortalToken,
        loggedInUser?.UserLevel,
        perPage,
        page + 1,
        searchType,
        searchValue,
        sortValue,
        sortDir,
        StartDate,
        EndDate,
        DateField,
        Status,
        SoldOnly,
        ShowLost,
        ServicesAry
      )
  );

  const { data, isSuccess, isLoading, refetch } = query;
  const sortByDate = useCallback(
    (value) => {
      sortDir === "Down" ? setSortDir("Up") : setSortDir("Down");
      setSortValue(value);
    },
    [sortDir]
  );

  useEffect(() => {
    if (isSuccess) {
      setTotal(parseInt(data.TotalResults, 10));
    }
  }, [data, isSuccess]);

  const applyFilters = (filters: filterParamsI) => {
    for (const [filter, value] of Object.entries(filters)) {
      // if they remove the DateFilter then we need to set these to null
      if (filter === "StartDate" || filter === "EndDate") {
        if (!filters.hasOwnProperty("DateField") || filters.DateField === "") {
          searchParams.delete(filter);
          continue;
        }
      }
      if (filter === "ShowLost" || filter === "SoldOnly") {
        if (!value) {
          searchParams.delete(filter);
        } else {
          searchParams.set(filter, "1");
        }
        continue;
      }
      if (filter === "Services") {
        const valString = value ? value.toString() : null;
        if (!valString) {
          searchParams.delete(filter);
        } else {
          searchParams.set(filter, valString);
        }
        continue;
      }

      if (typeof value === "string") {
        if (value === "") {
          searchParams.delete(filter);
        } else {
          searchParams.set(filter, value);
        }
      }
    }
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <>
      <PageMeta title="Clients/Leads" />
      <Nav loggedInUser={loggedInUser} />
      {/* body of the page */}
      <main className="w-full">
        {/* CTA in mobile screen */}
        <SearchCtaBtnsForMobile loggedInUser={loggedInUser} />
        {/* -------- */}
        <Header title="Clients/Leads" loggedInUser={loggedInUser} />
        {/* -------- */}
        <div className="flex md:hidden justify-around w-full p-5 pb-0">
          <div className="w-full mr-3">
            <a href={`${data?.ExportURL}`} download="export.csv">
              <CTAbtn type="button" text="EXPORT" color="os-btn-third" />
            </a>
          </div>
          <div className="w-full ml-3">
            <TableFiltersDrawer
              applyFilters={applyFilters}
              btnType="CTA"
              defaultFilterValues={defaultFilterValues}
            />
          </div>
        </div>
        {/* -------- */}
        <section className="flex flex-col w-full p-5 md:p-10">
          <div className="hidden lg:flex flex-col w-full border rounded-lg">
            <div className="flex w-full justify-between p-5">
              <span className="font-light">All Clients/Leads</span>
              <div className="flex">
                <ClientsSearch
                  searchValue={searchValue}
                  searchType={searchType}
                  setSearchValue={setSearchValue}
                  setSearchType={setSearchType}
                  refetch={refetch}
                />
                {!isLoading && (
                  <a href={`${data?.ExportURL}`} download="export.csv">
                    <Tooltip title="Download">
                      <i className="fas fa-download pl-5"></i>
                    </Tooltip>
                  </a>
                )}

                <TableFiltersDrawer
                  applyFilters={applyFilters}
                  btnType="icon"
                  defaultFilterValues={defaultFilterValues}
                />
              </div>
            </div>
            <title className="grid grid-cols-[4fr_2fr_4fr_2fr_2fr_4.5fr_2.5fr] gap-5 border-b p-5 items-center">
              <div>
                <span className="text-sm font-light">Client/Lead Name</span>
                <i
                  className={clsx(`fas ml-2 text-sm cursor-pointer`, {
                    "fa-caret-down":
                      sortDir === "Up" || sortValue !== "LastName",
                    "fa-caret-up": sortDir !== "Up" && sortValue === "LastName",
                  })}
                  onClick={() => sortByDate("LastName")}
                ></i>
              </div>
              <span className="text-sm font-light">Client ID</span>
              <span className="text-sm font-light">Address</span>
              <div>
                <span className="text-sm font-light">Date Added</span>
                <i
                  className={clsx(`fas ml-2 text-sm cursor-pointer`, {
                    "fa-caret-down":
                      sortDir === "Up" || sortValue !== "DateAdded",
                    "fa-caret-up":
                      sortDir !== "Up" && sortValue === "DateAdded",
                  })}
                  onClick={() => sortByDate("DateAdded")}
                ></i>
              </div>
              <div>
                <span className="text-sm font-light">Closing Date</span>
                <i
                  className={clsx(`fas ml-2 text-sm cursor-pointer`, {
                    "fa-caret-down":
                      sortDir === "Up" || sortValue !== "ClosingDate",
                    "fa-caret-up":
                      sortDir !== "Up" && sortValue === "ClosingDate",
                  })}
                  onClick={() => sortByDate("ClosingDate")}
                ></i>
              </div>
              <span className="text-sm font-light">Service Requested</span>
              <span className="text-sm font-light">Status</span>
            </title>
            {!isLoading && data?.Customers && (
              <ClientBlock
                device="desktop"
                customers={data?.Customers}
                setClientDetails={setClientDetails}
                setDetailsOpen={setDetailsOpen}
              />
            )}
            {isLoading && (
              <div className="flex w-full justify-center p-20">
                <InlineLoader />
              </div>
            )}
            {!isLoading && !data?.Customers && (
              <div className="flex w-full justify-center p-20">
                <span>No results found</span>
              </div>
            )}

            {!isLoading && data?.Customers && (
              <Pagination
                perPage={perPage}
                page={page}
                onPageChange={setPage}
                onPerPageChange={setPerPage}
                total={total}
              />
            )}
          </div>
          {/* mobile view */}
          <div className="flex lg:hidden flex-col w-full ">
            {!isLoading && (
              <ClientBlock
                device="mobile"
                customers={data?.Customers}
                setClientDetails={setClientDetails}
                setDetailsOpen={setDetailsOpen}
              />
            )}
            {isLoading && (
              <div className="flex w-full justify-center p-20">
                <InlineLoader />
              </div>
            )}
            {!isLoading && !data?.Customers && (
              <div className="flex w-full justify-center p-20">
                <span>No results found</span>
              </div>
            )}
            {!isLoading && (
              <Pagination
                perPage={perPage}
                page={page}
                onPageChange={setPage}
                onPerPageChange={setPerPage}
                total={total}
              />
            )}
          </div>
        </section>
        <ClientDetailsDrawer
          client={clientDetails}
          open={detailsOpen}
          setOpen={setDetailsOpen}
        />
      </main>
    </>
  );
}
