import React, { ReactElement } from "react";
import {Link} from "react-router-dom";
import InlineLoader from "../shared/InlineLoader";
import qs from 'qs';

interface DataBlockProps {
  title: string | any;
  num: number | any;
  isLoading?: boolean;
  linkTo?: string | boolean;
  filters?: {[filter: string]: string | null };
}
export default function DataBlock(props: DataBlockProps): ReactElement {
  const {title, num, isLoading, linkTo, filters} = props;
  let buildLink = linkTo?? false;
  if(filters && linkTo && typeof buildLink === 'string') {
    buildLink = buildLink + '?' +  qs.stringify(filters);
  }
  return (
    <div className="flex flex-col mb-5">
      <span className="text-sm font-light mb-5">{title}</span>

      {!isLoading && !linkTo && (
        <span className="text-4xl font-light ">{num}</span>
      )}

      {!isLoading && linkTo && typeof buildLink === 'string' && (

        <Link to={buildLink}><span className="text-4xl font-light ">{num}</span></Link>
      )}
      {props.isLoading && <InlineLoader />}
    </div>
  );
}
