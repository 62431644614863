import React, { useCallback, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import "react-toastify/dist/ReactToastify.css";
import { format, lastDayOfMonth } from "date-fns";
import clsx from "clsx";

interface MonthRangePickerProps {
  minYear?: number;
  maxYear?: number;
  handleApply: (beginDate: string, endDate: string) => void;
  initialBeginDate?: Date | null;
  initialEndDate?: Date | null;
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function MonthRangePickerModal(props: MonthRangePickerProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { minYear = 1970, maxYear = 2050, handleApply, initialEndDate, initialBeginDate } = props;
  const initBeginYear = initialBeginDate? format(initialBeginDate, 'yyyy') : format(new Date(), 'yyyy');
  const initBeginMonth = initialBeginDate? format(initialBeginDate, 'MM') : format(new Date(), 'MM');
  const initEndYear = initialEndDate? format(initialEndDate, 'yyyy') : format(new Date(), 'yyyy');
  const initEndMonth = initialEndDate? format(initialEndDate, 'MM') : format(new Date(), 'MM');
  const [beginYear, setBeginYear] = useState<number | null>(parseInt(initBeginYear));
  const [endYear, setEndYear] = useState<number | null>(parseInt(initEndYear));
  const [beginMonth, setBeginMonth] = useState<number | null>(parseInt(initBeginMonth));
  const [endMonth, setEndMonth] = useState<number | null>(parseInt(initEndMonth));
  const [applied, setApplied] = useState(false);

  // useEffect(() => {
  //   if(!initialBeginDate && !initialEndDate) {
  //     const currentDate = new Date();
  //     setBeginYear(() => currentDate.getUTCFullYear());
  //     setEndYear(() => currentDate.getUTCFullYear() + 1);
  //   }
  // }, []);

  const monthMap = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const startDateDisplay = useMemo(() => {
    if (
      beginMonth !== null &&
      beginYear !== null &&
      beginMonth !== undefined &&
      beginYear !== undefined
    ) {
      return new Date(beginYear, beginMonth - 1, 1).toLocaleDateString();
    }
    return "Start Date";
  }, [beginYear, beginMonth]);

  const endDateDisplay = useMemo(() => {
    if (
      endMonth !== null &&
      endYear !== null &&
      endMonth !== undefined &&
      endYear !== undefined
    ) {
      const end = new Date(endYear, endMonth - 1, 1);
      return format(lastDayOfMonth(end), "MM/dd/yyyy");
    }
    return "End Date";
  }, [endYear, endMonth]);

  const updateBeginYear = useCallback(
    (type = "inc") => {
      if(!beginYear || !endYear) {
        return;
      }
      let newYear = type === "inc" ? beginYear + 1 : beginYear - 1;
      if (newYear > endYear) {
        newYear = endYear;
      }
      if (newYear < minYear) {
        newYear = minYear;
      }
      setBeginYear(newYear);
    },
    [beginYear, endYear, minYear]
  );

  const updateEndYear = useCallback(
    (type = "inc") => {
      if(!beginYear || !endYear) {
        return;
      }
      let newYear = type === "inc" ? endYear + 1 : endYear - 1;
      if (newYear < beginYear) {
        newYear = beginYear;
      }
      if (newYear > maxYear) {
        newYear = maxYear;
      }
      setEndYear(newYear);
    },
    [beginYear, endYear, maxYear]
  );

  const updateBeginMonth = useCallback(
    (val) => {
      let newMonth = val;
      if (
        endMonth !== null &&
        endMonth !== undefined &&
        beginYear === endYear &&
        newMonth > endMonth
      ) {
        newMonth = endMonth;
      }
      setBeginMonth(newMonth);
    },
    [beginYear, endYear, endMonth]
  );

  const updateEndMonth = useCallback(
    (val) => {
      let newMonth = val;
      if (
        beginMonth !== null &&
        beginMonth !== undefined &&
        beginYear === endYear &&
        newMonth < beginMonth
      ) {
        newMonth = beginMonth;
      }
      setEndMonth(newMonth);
    },
    [beginYear, endYear, beginMonth]
  );

  const beginMonths = () => {
    return monthMap.map((label, value) => (
      <div
        className={clsx(
          `flex text-sm rounded-full  w-10 h-10 justify-center items-center cursor-pointer`,
          {
            "bg-os-btn-third hover:bg-os-btn-third text-white":
              beginMonth === value + 1,

            "bg-gray-200": beginMonth! < value + 1,
          }
        )}
        key={value}
        onClick={() => updateBeginMonth(value + 1)}
      >
        <span>{label}</span>
      </div>
    ));
  };

  const endMonths = () => {
    return monthMap.map((label, value) => (
      <div
        className={clsx(
          `flex text-sm rounded-full bg-g  w-10 h-10 justify-center items-center cursor-pointer`,
          {
            "bg-os-btn-third hover:bg-os-btn-third text-white":
              endMonth === value + 1,
            "bg-gray-200": endMonth! > value + 1,
          }
        )}
        key={value}
        onClick={() => updateEndMonth(value + 1)}
      >
        <span>{label}</span>
      </div>
    ));
  };

  const apply = useCallback(() => {
    if (
      beginMonth !== null &&
      beginMonth !== undefined &&
      beginYear !== null &&
      beginYear !== undefined &&
      endMonth !== null &&
      endMonth !== undefined &&
      endYear !== null &&
      endYear !== undefined
    ) {
      const beginDate = `${beginYear}-${beginMonth}-01`;
      const end = new Date(endYear, endMonth - 1, 1);
      const lastDateOfMonth = format(lastDayOfMonth(end), "yyyy-MM-dd");
      handleApply(beginDate, lastDateOfMonth);
    }
    setApplied(true);
    handleClose();
  }, [endYear, endMonth, beginYear, beginMonth, handleApply]);

  // const handleReset = useCallback(() => {
  //   setBeginMonth(null);
  //   setEndMonth(null);
  //   handleApply("", "");
  //   setApplied(false);
  //   handleClose();
  // }, [handleApply]);

  return (
    <div>
      <div className="flex items-center border-b-2 border-black">
        <span className="text-sm" onClick={handleClickOpen}>
          {startDateDisplay} To {endDateDisplay}
        </span>
        {applied && (
          <button onClick={handleClose}>
            <i className="fa-regular fa-times ml-5"></i>
          </button>
        )}
        <button onClick={handleClickOpen}>
          <i className="fa-regular fa-calendar ml-5"></i>
        </button>
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent dividers>
          <div style={{ minWidth: 280 }} className="rounded-2xl border-2 p-5">
            <div className="flex justify-center align-center gap-5 mb-3 border-b-2 pb-3">
              <div>{startDateDisplay}</div>
              <div>
                <i className="fas fa-arrow-right-long" />
              </div>
              <div>{endDateDisplay}</div>
            </div>
            <div className="sm:flex justify-center align-center gap-10">
              <div className=" border-gray-300 mb-10 sm:mb-0">
                <div className="flex justify-between align-center gap-2 mb-5 rounded-2xl bg-gray-50 px-2">
                  <button onClick={() => updateBeginYear("dec")}>
                    <i className="fas fa-angle-left" />
                  </button>
                  {beginYear}
                  <button onClick={() => updateBeginYear("inc")}>
                    <i className="fas fa-angle-right" />
                  </button>
                </div>
                <div className="grid gap-5 grid-cols-4 flex-grow ">
                  {beginMonths()}
                </div>
              </div>
              <div className=" border-gray-300">
                <div className="flex justify-between align-center gap-2 mb-5 rounded-2xl bg-gray-50 px-2">
                  <button onClick={() => updateEndYear("dec")}>
                    <i className="fas fa-angle-left" />
                  </button>
                  {endYear}
                  <button onClick={() => updateEndYear("inc")}>
                    <i className="fas fa-angle-right" />
                  </button>
                </div>
                <div className="grid gap-5 grid-cols-4 flex-grow">
                  {endMonths()}
                </div>
              </div>
            </div>

            <div className="flex align-center justify-end gap-3 mt-3">
              <button
                onClick={handleClose}
                className="px-2 py-1 rounded-2xl border-2"
              >
                Cancel
              </button>
              <button
                onClick={apply}
                disabled={
                  beginMonth === null ||
                  beginMonth === undefined ||
                  beginYear === null ||
                  beginYear === undefined ||
                  endMonth === null ||
                  endMonth === undefined ||
                  endYear === null ||
                  endYear === undefined
                }
                className="bg-os-btn-third px-2 py-1 rounded-2xl text-white disabled:opacity-70 disabled:cursor-not-allowed"
              >
                Apply
              </button>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
