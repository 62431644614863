import React, { ReactElement } from "react";
import { serviceSummaryFromCustomer } from "../../helpers/Utilities";
import { slashDate } from "../../helpers/dateTime";
import { Customer } from "../../interfaces/Customer";

import clsx from "clsx";

interface ClientBlockProps {
  device: string;
  customers: Customer[];
  setDetailsOpen: (value: boolean) => void;
  setClientDetails: (value) => void;
}

export default function ClientBlock(
  props: ClientBlockProps
): ReactElement | any {
  const { device, customers, setDetailsOpen, setClientDetails } = props;

  const openDetailsDrawer = (client) => {
    setClientDetails(client);
    setDetailsOpen(true);
  };

  if (customers && device === "desktop") {
    return customers.map((customer: any, index) => (
      <div
        key={index}
        className="grid grid-cols-[4fr_2fr_4fr_2fr_2fr_4.5fr_2.5fr] gap-5 items-center border-b p-5 shadow hover:shadow-md"
      >
        <div className="flex flex-col ">
          <span
            className="text-sm font-medium cursor-pointer"
            onClick={() => {
              openDetailsDrawer(customer);
            }}
          >
            {customer.LastName}
            {customer.LastName ? ", " : ""}
            {customer.FirstName}
          </span>
          <span className="text-sm font-light">
            Agent: {customer.AgentName}
          </span>
        </div>
        <span className="text-sm font-medium">{customer.CustomerID}</span>
        <div className="flex flex-col ">
          <span className="text-sm font-medium">{customer.Address}</span>
          <span className="text-sm font-light">{customer.City}</span>
        </div>
        <span className="text-sm font-medium">
          {customer?.DateAdded === "" || customer?.DateAdded === null
            ? "00/00/0000"
            : slashDate(customer.DateAdded)}
        </span>
        <span className="text-sm font-medium">
          {slashDate(customer.ClosingDate)}
        </span>
        <span className="text-sm font-medium">
          {serviceSummaryFromCustomer(customer)}
        </span>
        <span
          className={clsx(
            `text-white w-fit text-xs uppercase rounded-full text-center px-5 py-1`,
            `bg-os-status-${customer.Status.split(" ").join("")}`
          )}
        >
          {customer.Status}
        </span>
      </div>
    ));
  }
  if (customers && device === "mobile") {
    return customers.map((customer: any, index) => (
      <div
        key={index}
        className="flex flex-col border rounded-xl w-full p-5 mb-5"
      >
        <span
          className={`text-white w-fit text-xs uppercase bg-os-status-${customer.Status.split(
            " "
          ).join("")} rounded-full text-center px-5 py-1`}
        >
          {customer.Status}
        </span>
        <div className="grid grid-cols-[2.5fr_1fr] gap-2 gap-y-7 w-full py-5">
          <div className="flex flex-col ">
            <span className="text-xs mb-1">Client/Lead Name</span>
            <span className="text-sm font-light">
              {customer.LastName}
              {customer.LastName ? ", " : ""}
              {customer.FirstName}
            </span>
            <span className="text-xs font-light">
              Agent: {customer.AgentName}
            </span>
            <span className="text-xs font-light">
              Client ID: {customer.CustomerID}
            </span>
          </div>
          <div className="flex flex-col text-right">
            <span className="text-xs mb-2">Date Added</span>

            <span className="text-sm font-light">
              {customer?.DateAdded === "" || customer?.DateAdded === null
                ? "00/00/0000"
                : slashDate(customer.DateAdded)}
            </span>
          </div>
          <div className="flex flex-col ">
            <span className="text-xs mb-1">Address</span>
            <span className="text-sm font-light">{customer.Address}</span>
            <span className="text-xs font-light">{customer.City}</span>
          </div>

          <div className="flex flex-col text-right">
            <span className="text-xs mb-2">Closing Date</span>

            <span className="text-sm font-light">
              {slashDate(customer.ClosingDate)}
            </span>
          </div>
          <div className="flex flex-col col-span-2">
            <span className="text-xs mb-1">Services Requested</span>
            <span className="text-sm font-light">
              {serviceSummaryFromCustomer(customer)}
            </span>
          </div>
        </div>
        <div className="flex justify-end">
          <span onClick={() => openDetailsDrawer(customer)}>VIEW DETAILS</span>
        </div>
      </div>
    ));
  }
}
