import React, { ReactElement, useState } from "react";
import logoReversed from "../../assets/logos/onesource-reversed.svg";
import { LoggedInUser } from "../../interfaces/LoggedInUser";
import AccountMenu from "../modals/AccountMenu";
import ReferralForm from "../modals/ReferralForm";
import GlobalSearch from "../modals/GlobalSearch";
import MenuDrawer from "../modals/Menu";
import NavPagesBtns from "./NavPagesBtns";
// import Notifications from "../modals/Notifications";
import AgentDetailsDrawer from "../../components/modals/AgentDetails";
import OfficeDetailsDrawer from "../../components/modals/OfficeDetails";

import { Link } from "react-router-dom";

interface NavProps {
  loggedInUser: LoggedInUser | null;
}

export default function Nav(props: NavProps): ReactElement {
  const { loggedInUser } = props;

  const [agentDetails, setAgentDetails] = useState();
  const [agentDetailsOpen, setAgentDetailsOpen] = useState(false);
  const [officeDetails, setOfficeDetails] = useState();
  const [officeDetailsOpen, setOfficeDetailsOpen] = useState(false);

  return (
    <nav className="sticky grid grid-cols-2 lg:grid-cols-3 top-0 z-50 px-5 lg:px-10 app-default-bg w-full h-20 ">
      <div className="flex items-center w-full h-full">
        <button className="inline-block lg:hidden flex-col justify-around items-center mr-5">
          <MenuDrawer
            icon={<i className="fas fa-bars text-white text-xl"></i>}
            loggedInUser={loggedInUser}
          />
        </button>
        <Link to={"/"}>
          <img
            src={logoReversed}
            alt="OneSource solutions"
            className="w-[130px]"
          />
        </Link>
        <span className="hidden md:inline-block text-2xl text-white ml-4">
          Partner Portal ({loggedInUser?.UserLevel})
        </span>
      </div>

      <div className="hidden lg:flex justify-center h-full ">
        <NavPagesBtns loggedInUser={loggedInUser} />
      </div>
      <div className="flex flex-row-reverse items-center h-full">
        <AccountMenu loggedInUser={loggedInUser} />
        {/* {loggedInUser && loggedInUser.UserLevel === "Agent" && (
          <Notifications />
        )} */}
        {loggedInUser && loggedInUser.UserLevel !== "Agent" && (
          <GlobalSearch
            buttonType="icon"
            loggedInUser={loggedInUser}
            setAgentDetails={setAgentDetails}
            setAgentDetailsOpen={setAgentDetailsOpen}
            setOfficeDetails={setOfficeDetails}
            setOfficeDetailsOpen={setOfficeDetailsOpen}
          />
        )}
        {loggedInUser && loggedInUser.UserLevel === "Agent" && (
          <div className="hidden md:block">
            <ReferralForm />
          </div>
        )}
      </div>
      <AgentDetailsDrawer
        agent={agentDetails}
        open={agentDetailsOpen}
        setOpen={setAgentDetailsOpen}
      />
      <OfficeDetailsDrawer
        office={officeDetails}
        open={officeDetailsOpen}
        setOpen={setOfficeDetailsOpen}
      />
    </nav>
  );
}
