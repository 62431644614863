import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./tailwind.generated.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ContextProvider } from "./context/ContextProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import AppToasts from "./AppToasts";
import { LoadScript } from "@react-google-maps/api";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <ContextProvider>
      <QueryClientProvider client={queryClient}>
        <AppToasts>
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY || ""}
          >
            <App />
          </LoadScript>
        </AppToasts>
      </QueryClientProvider>
    </ContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
