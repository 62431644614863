import React, { ReactElement, useCallback } from "react";
import { Office } from "../../interfaces/Office";
import { LoggedInUser } from "../../interfaces/LoggedInUser";
import { toggleShareRevenueRequest } from "../../api/offices/toggleShareRevenueRequest";
import Tooltip from "@mui/material/Tooltip";
import { addressFirstLine, addressSecondLine } from "../../helpers/string";
import { Link } from "react-router-dom";
import { format } from "date-fns";

interface OfficeBlockProps {
  loggedInUser: LoggedInUser | null;
  device: string;
  offices: Office[];
  setDetailsOpen: (value: boolean) => void;
  setOfficeDetails: (value) => void;
}

export default function OfficeBlock(
  props: OfficeBlockProps
): ReactElement | any {
  const { device, offices, loggedInUser, setDetailsOpen, setOfficeDetails } =
    props;

  const openDetailsDrawer = (client) => {
    setOfficeDetails(client);
    setDetailsOpen(true);
  };
  const toggleShareRevenue = useCallback(
    async (officeToken: string, isChecked: boolean) => {
      const checkedValue = isChecked ? "Yes" : "No";
      await toggleShareRevenueRequest(
        loggedInUser?.PortalToken,
        loggedInUser?.UserLevel,
        officeToken,
        checkedValue
      );
    },
    [loggedInUser]
  );
  const monthOfYear = format(new Date(), "MM/yyyy");
  if (offices && device === "desktop") {
    return offices.map((office: any, index) => (
      <div
        key={index}
        className="grid grid-cols-[3fr_1fr_1fr_1fr_1fr_0.5fr] gap-5 items-center border-b p-5 shodow hover:shadow-md"
      >
        <div className="flex flex-col ">
          <span
            className="text-sm font-medium cursor-pointer"
            onClick={() => {
              openDetailsDrawer(office);
            }}
          >
            {office.OfficeName}
          </span>
          <span className="text-sm font-light">
            Office #: {office.OfficeID}
          </span>
        </div>

        <span className="text-sm font-medium">{office.LeadsSubmitted}</span>

        <span className="text-sm font-medium">
          $ {office?.MonthlyRevenue[monthOfYear]}
        </span>

        <span className="text-sm font-medium">
          {office.YTDRevenue ?? "---"}
        </span>

        <div className="toggleSlider flex items-center">
          <div className="off-state-label mr-2 text-xs">OFF</div>

          <label className="flex items-center cursor-pointer">
            <div className="relative">
              <input
                type="checkbox"
                key={office.OfficeToken}
                className="sr-only"
                disabled={false}
                defaultChecked={office.RevenueSharing === "1"}
                onChange={(event) =>
                  toggleShareRevenue(office.OfficeToken, event.target.checked)
                }
              />
              <div className="slider block bg-gray-10 border border-black w-6 h-3 rounded-full"></div>
              <div className="dot absolute left-1 top-0.5 bg-black w-2 h-2 rounded-full transition"></div>
            </div>
          </label>
          <div className="on-state-label ml-2 text-xs">ON</div>
        </div>
        <div className="flex justify-end">
          <Link to={`/`} state={{ officeToken: office.OfficeToken }}>
            <Tooltip title="View As">
              <i className="fas fa-eye"></i>
            </Tooltip>
          </Link>
        </div>
      </div>
    ));
  }
  if (offices && device === "mobile") {
    return offices.map((office: any, index) => (
      <div
        key={index}
        className="flex flex-col border rounded-xl w-full p-5 mb-5"
      >
        <div className="flex">
          <span className="text-xs font-light mr-5">REVENUE SHARING</span>
          <div className="toggleSlider flex items-center">
            <div className="off-state-label mr-2 text-xs">OFF</div>

            <label className="flex items-center cursor-pointer">
              <div className="relative">
                <input
                  type="checkbox"
                  className="sr-only"
                  disabled={false}
                  defaultChecked={office.RevenueSharing === "1"}
                  onChange={(event) =>
                    toggleShareRevenue(office.OfficeToken, event.target.checked)
                  }
                />
                <div className="slider block bg-gray-10 border border-black w-6 h-3 rounded-full"></div>
                <div className="dot absolute left-1 top-0.5 bg-black w-2 h-2 rounded-full transition"></div>
              </div>
            </label>
            <div className="on-state-label ml-2 text-xs">ON</div>
          </div>
        </div>

        <div className="grid grid-cols-[2.5fr_1fr] gap-2 gap-y-7 w-full py-5">
          <div className="flex flex-col ">
            <span className="text-xs mb-1">Office Name</span>
            <span
              className="text-sm font-light cursor-pointer"
              onClick={() => {
                openDetailsDrawer(office);
              }}
            >
              {office.OfficeName}
            </span>
            <span className="text-xs font-light">
              Office #: {office.OfficeID}
            </span>
          </div>
          <div className="flex flex-col text-right">
            <span className="text-xs mb-1">Monthly Revenue</span>
            <span className="text-sm font-light">
              $ {office?.MonthlyRevenue[monthOfYear]}
            </span>
          </div>
          <div className="flex flex-col ">
            <span className="text-xs mb-1">Address</span>
            <span className="text-sm font-light">
              {addressFirstLine(office)}
            </span>
            <span className="text-xs font-light">
              {addressSecondLine(office)}
            </span>
          </div>

          <div className="flex flex-col text-right">
            <span className="text-xs mb-1">YTD Revenue</span>

            <span className="text-sm font-light">
              {office.YTDRevenue ?? "---"}
            </span>
          </div>
          <div className="flex flex-col col-span-2">
            <span className="text-xs mb-1">Leads Added</span>
            <span className="text-sm font-light">{office.LeadsSubmitted}</span>
          </div>
        </div>
        <div className="flex justify-between">
          <Link
            to={`/`}
            state={{ officeToken: office.OfficeToken }}
            className="w-fit text-sm"
          >
            VIEW AS OFFICE
          </Link>
          <button
            className="w-fit text-sm"
            onClick={() => {
              openDetailsDrawer(office);
            }}
          >
            VIEW DETAILS
          </button>
        </div>
      </div>
    ));
  }
}
