import React, { ReactElement, useState } from "react";
import { LoggedInUser } from "../../interfaces/LoggedInUser";
import GlobalSearch from "../modals/GlobalSearch";
import ReferralForm from "../modals/ReferralForm";
import AgentDetailsDrawer from "../../components/modals/AgentDetails";
import OfficeDetailsDrawer from "../../components/modals/OfficeDetails";

interface SearchCtaBtnsForMobileProps {
  loggedInUser: LoggedInUser | null;
}
export default function SearchCtaBtnsForMobile(
  props: SearchCtaBtnsForMobileProps
): ReactElement {
  const { loggedInUser } = props;

  const [agentDetails, setAgentDetails] = useState();
  const [agentDetailsOpen, setAgentDetailsOpen] = useState(false);
  const [officeDetails, setOfficeDetails] = useState();
  const [officeDetailsOpen, setOfficeDetailsOpen] = useState(false);
  return (
    <div className="flex md:hidden justify-around w-full p-5">
      {loggedInUser && loggedInUser.UserLevel !== "Agent" && (
        <div className="w-full">
          <GlobalSearch
            buttonType="CTA"
            loggedInUser={loggedInUser}
            setAgentDetails={setAgentDetails}
            setAgentDetailsOpen={setAgentDetailsOpen}
            setOfficeDetails={setOfficeDetails}
            setOfficeDetailsOpen={setOfficeDetailsOpen}
          />
        </div>
      )}
      {loggedInUser && loggedInUser.UserLevel === "Agent" && (
        <div className="w-full">
          <ReferralForm />
        </div>
      )}
      <AgentDetailsDrawer
        agent={agentDetails}
        open={agentDetailsOpen}
        setOpen={setAgentDetailsOpen}
      />
      <OfficeDetailsDrawer
        office={officeDetails}
        open={officeDetailsOpen}
        setOpen={setOfficeDetailsOpen}
      />
    </div>
  );
}
